import axiosInstance from './config'
import {EventSourcePolyfill} from 'event-source-polyfill'

const generate = (id, basicInfo, input, para, paraNum) => {
    return axiosInstance.post(`/api/generate`, {
        school: basicInfo.currentSchool,
        major: basicInfo.currentMajor,
        gpa: basicInfo.currentGPA,
        targetSchool: basicInfo.applySchool,
        targetMajor: basicInfo.applyMajor,
        input: input,
        paragraph: para,
        paragraphNum: paraNum,
        id: id
    })
}

const generateFinal = (id, eventId, basicInfo, input, para, paraNum) => {
    return axiosInstance.post(`/api/generate/final`, {
        school: basicInfo.currentSchool,
        major: basicInfo.currentMajor,
        gpa: basicInfo.currentGPA,
        targetSchool: basicInfo.applySchool,
        targetMajor: basicInfo.applyMajor,
        input: input,
        paragraph: para,
        paragraphNum: paraNum,
        eventId: eventId,
        id: id
    })
}

const saveChoice = (data) => {
    return axiosInstance.post(`/userEssay/save`, data);
}

const sseConnect = () => {
    return new EventSourcePolyfill(`/sse`, {
        headers: {
            'Authorization': localStorage.getItem("serviceToken")
        }
    });
}

export {generate, generateFinal, saveChoice, sseConnect}