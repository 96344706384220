import React from 'react';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { IconDeviceFloppy } from '@tabler/icons';

function ExportToWordButton({content, fileName}) {
    let paragraphs;
    if (content instanceof Array) {
        paragraphs = content.map((para) => {
            return new Paragraph({
                children: [
                    new TextRun({
                        text: para
                    })
                ]
            })
        })
    } else {
        paragraphs = content instanceof Array ? content.split("\n\n").map((para) => {
            return new Paragraph({
                children: [
                    new TextRun({
                        text: para
                    })
                ]
            })
        }): []
    }
    const exportToWord = async () => {
        const doc = new Document({
            sections: [
                {
                    properties: {},
                    children: paragraphs
                }
            ]
        });
        Packer.toBlob(doc).then((blob) => {
            saveAs(blob, `${fileName}.docx`)
        })
    };
    return <IconDeviceFloppy color="#2196f3" onClick={() => exportToWord()} aria-label="导出" />;
}

export default ExportToWordButton;
