import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axiosInstance from 'api/config';
import { checkLogin } from 'api/auth';

import { openSnackbar } from 'store/slices/snackbar';
import { useSelector } from 'react-redux';
import { login, logout } from 'store/slices/account';

import { useDispatch } from 'react-redux';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};


// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const loginStatus = useSelector(state => state.account);
    const dispatch = useDispatch();

    useEffect( () => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken) {
                    localStorage.setItem('serviceToken', serviceToken);
                    // check if the token is expired
                    checkLogin().then( res => {
                        if (res.data.user) {
                            dispatch(
                                login({
                                    isLoggedIn: true,
                                    user: res.data.user.email
                                })
                            );
                        } else {
                            dispatch(
                                logout({
                                    isLoggedIn: false,
                                    user: null
                                })
                            );
                        }
                    })
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    // const getRegOTP = async (email) => {
    //     await axios.post('/auth/sendVerificationCode', {email: email}, {
    //         headers: {
    //             'Content-Type': 'Application/json'
    //         }
    //     }).then( res => {
    //         if (res.status === 200) {
    //             dispatch(
    //                 openSnackbar({
    //                     message: '验证码已发送，请检查邮箱',
    //                     variant: 'alert',
    //                     alert: { color: 'success' },
    //                 })
    //             )
    //         } else {
    //             const { message } = res.data;
    //             dispatch(
    //                 openSnackbar({
    //                     message: `验证码发送失败, ${message}`,
    //                     variant: 'alert',
    //                     alert: { color: 'error' },
    //                 })
    //             )
    //         }
    //         return res.status;
    //     }).catch( err => {
    //         console.log(err)
    //         return err
    //     })
        
    // }

    // const login = async (email, password) => {
    //     const response = await axios.post('/auth/login', { email, password });
    //     const { serviceToken, user } = response.data;
    //     setSession(serviceToken);
    //     dispatch({
    //         type: LOGIN,
    //         payload: {
    //             isLoggedIn: true,
    //             user
    //         }
    //     });
    // };

    // const register = async (email, password, otp) => {
    //     // todo: this flow need to be recode as it not verified
    //     const id = chance.bb_pin();
    //     const response = await axios.post('/auth/register', {
    //         email,
    //         password,
    //         otp
    //     });
    //     let users = response.data;

    //     if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
    //         const localUsers = window.localStorage.getItem('users');
    //         users = [
    //             ...JSON.parse(localUsers),
    //             {
    //                 id,
    //                 email,
    //             }
    //         ];
    //     }

    //     window.localStorage.setItem('users', JSON.stringify(users));
    // };

    // const logout = () => {
    //     setSession(null);
    //     dispatch({ type: LOGOUT });
    // };

    // const resetPassword = (email) => console.log(email);

    // const updateProfile = () => {};

    // if (state.isInitialized !== undefined && !state.isInitialized) {
    //     return <Loader />;
    // }

    return (
        <JWTContext.Provider value={{ ...loginStatus}}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
