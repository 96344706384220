// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconTools, IconWindmill, IconClipboardList, IconChartInfographic, IconLayoutGridAdd, IconCheck, IconExclamationCircle } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconTools,
    IconWindmill,
    IconClipboardList,
    IconChartInfographic,
    IconLayoutGridAdd,
    IconCheck,
    IconExclamationCircle
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const template = {
    id: 'template',
    title: '文书采集表',
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'addtemplate',
            title: '上传',
            type: 'item',
            url: '/ps/addtemplate',
            icon: icons.IconBrandChrome,
            status: 0
        }
    ]
};

export default template;
