import GuestGuard from 'utils/route-guard/GuestGuard';
import NavMotion from 'layout/NavMotion';

import AuthLogin from 'views/authentication/Login3'
import AuthRegister from 'views/authentication/Register3'
import AuthForgotPassword from 'views/authentication/auth-forms/AuthForgotPassword';
import MinimalLayout from 'layout/MinimalLayout';


const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        }
    ]
};

export default LoginRoutes;
