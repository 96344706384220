import ps from './ps';
import guidance from './guidance';
import manage from './manage';
import template from './template';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [guidance, manage, template, ps]
};

export default menuItems;
