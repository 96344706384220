import axiosInstance from './config'

const createNewPS = (email, title) => {
   return axiosInstance.post(`/userEssay/create`, {email: email, title: title})
}

const getAllPS = (user) => {
    return axiosInstance.get(`/userEssay/findByEmail?email=${user}`)
}

const getPS = (psID) => {
    return axiosInstance.get(`/userEssay/findEssayDetail?id=${psID}`)
}

const deletePS = (psID) => {
    return axiosInstance.post(`/userEssay/delete`, {id: psID})
}

export {createNewPS, getAllPS, getPS, deletePS}