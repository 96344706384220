import { Card, CardContent, Grid, useTheme, Button } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import React, { useState } from 'react';

// ==============================|| SelectableCard ||============================== //

function SelectableCard({ index, content, isSelected, onSelect, onContentChange }) {
    const [value, setValue] = useState(content);
    const [isEditable, setIsEditable] = useState(false);
    const handleEdit = () => {
        setIsEditable(true);
    };
    const theme = useTheme();
    const cardStyle = {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]
    }
    const handleSave = () => {
        setIsEditable(false);
        onContentChange(value,index);
        // 这里可以添加保存逻辑
    };

    const handleCancel = () => {
        setIsEditable(false);
        setValue(content); // 重置文本为原始内容
    };

    return (
        <Grid
            item
            sx={{
                cursor: 'pointer',
                '&:hover': {
                    outline: '3px solid #2196f3', // Slight scale effect on hover
                    borderRadius: '13px'
                }
            }}
            xs={12}
            style={{
                marginBottom: '20px',
                fontSize: '17px',
                lineHeight: '24px',
                color: 'blue',
                fontWeight: '300'
            }}
        >
            <Card sx={cardStyle}>
                <CardContent
                    onClick={() => onSelect(index)}
                    item
                    sx={{
                        backgroundColor: isSelected ? '#bbdefb' : 'none'
                    }}
                >
                    {isEditable ? (
                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={3}
                            style={{ width: '100%' }}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    ) : (
                        <span>{value}</span>
                    )}

                    {!isEditable ? (
                        <Button onClick={handleEdit}>编辑</Button>
                    ) : (
                        <>
                            <Button onClick={handleSave}>保存</Button>
                            <Button onClick={handleCancel}>取消</Button>
                        </>
                    )}
                </CardContent>
            </Card>
        </Grid>
    );
}

export default SelectableCard;