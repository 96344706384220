import { useDispatch } from 'store';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useState, useEffect } from 'react';
// material-ui
import { Button, Grid, Stack, TextField, Skeleton, Typography, ListItemIcon } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { Done, Error } from '@mui/icons-material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import FileUploader from 'ui-component/extended/FileUploader';
import { openSnackbar } from 'store/slices/snackbar';
import submitBasicInfo from 'api/basicinfo'
import { saveBasicInfo, setContentID, setID, setTitle, setContentIDs, setIns, saveInterest, saveOther, savePlan, saveEducation, setOldChosen } from 'store/slices/psData';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getPS } from 'api/ps';
import { store } from 'store';
import { parseTemplate } from 'api/parseTemplate';
import { generate, saveChoice } from 'api/generate';
import { createNewPS } from 'api/ps';
import { updateArray } from 'utils/helper-func';
import { getCredit } from 'api/credit';
import { setCredit } from 'store/slices/account';

// ==============================|| FORM VALIDATION - INSTANT FEEDBACK FORMIK  ||============================== //

const UploadTemplate = () => {
  const [fillingStatus, setStatus] = useOutletContext();
  const [basicInfo, setBasicInfo] = useState({
    currentSchool: '',
    currentMajor: '',
    applySchool: '',
    applyMajor: '',
    currentGPA: ''
  });
  const [loading, setLoading] = useState(false);
  const [initing, setIniting] = useState(false);
  const [choosingRes, setChoosingRes] = useState(false);
  const [parsed, setParsed] = useState({
    interest: '',
    academic: '',
    future: '',
    other: ''
  });

  const [showResult, setShowResult] = useState(false);
  const [resultStatus, setResultStatus] = useState([-1, -1, -1, -1, -1]);
  const [resultErrorMsg, setResultErrorMsg] = useState('');
  const [tryAgain, setTryAgain] = useState(false);
  const [flag, setFlag] = useState(1);
  const [flag2, setFlag2] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const intl = useIntl();

  const user = useSelector(state => state.account).user;
  const title = useSelector(state => state.psData).title;
  const id = useSelector(state => state.psData).id;
  const contentIDs = useSelector(state => state.psData).contentIDs;

  const saveFuncs = [null, saveInterest, saveEducation, saveOther, savePlan];

  const handleEdit = (key, value) => {
    const newBasicInfo = {
      ...basicInfo,
    }
    newBasicInfo[key] = value;
    setBasicInfo(newBasicInfo);
    setStatus({basicinfo: 1});
  }

  const parsePS = async (file) => {
    let formData = new FormData();
    formData.append('file', file)
    await parseTemplate(formData).then( res => {
      if (res.data) {
        setParsed(res.data.result)
        dispatch(openSnackbar({
          message: intl.formatMessage({id: "Create PS Success"}),
          variant: 'alert',
          alert: { color: 'success' },
        }))
        dispatch(
          setCredit(res.data.credit)
        )
      }
    }).catch( err => {
      if (err.response.data.errorCode == 2) {
        dispatch(openSnackbar({
          message: intl.formatMessage({id: "Credit Inadequate"}),
          variant: 'alert',
          alert: { color: 'error' },
        }))
      } 
      dispatch(openSnackbar({
        message: intl.formatMessage({id: "Create PS Failed"}),
        variant: 'alert',
        alert: { color: 'error' },
      }))
    })
  }

  const createPS = async () => {
    const psTitle = title || 'newps_' + Date.now()
    updateArray(resultStatus, 0, 1, setResultStatus);
    
    let res = await createNewPS(user, psTitle);
    if (res.data.id) {
      dispatch(openSnackbar({
        message: intl.formatMessage({id: "Create PS Success"}),
        variant: 'alert',
        alert: { color: 'success' },
      }))
      
      dispatch(setID(res.data.id))
      dispatch(setTitle(psTitle))
      dispatch(saveBasicInfo(basicInfo))
      dispatch(setContentIDs([res.data.basicInfoId, ...res.data.paragraphIds, res.data.previewId]))
      updateArray(resultStatus, 0, 2, setResultStatus);
      setFlag2(2);
    } else {
      console.log(res.data.message)
      updateArray(resultStatus, 0, 3, setResultStatus);
      dispatch(openSnackbar({
        message: intl.formatMessage({id: "Create PS Failed"}),
        variant: 'alert',
        alert: { color: 'error' },
      }))
      setResultErrorMsg("文书创建失败，请重试");
      setTryAgain(true);
    }
  }

  const genParas = () => {
    const reqs = [];

    for(let i = 0; i < 4; i++) {
      if(resultStatus[i] === 3) break;
      else {
        const req = generate(contentIDs[1], basicInfo, parsed.interest, '', 1).catch(err => err)
        reqs.push(req);
        setResultStatus(prev => {
          const newResultStatus = [...prev];
          newResultStatus[i] = 1;
          return newResultStatus
        })
      }
    }
    
    dispatch(setIns([null, parsed.interest, parsed.academic, parsed.other, parsed.future, null]));
    Promise.all(reqs).then(res => {
      res.forEach(res => {
        const type = JSON.parse(res.config.data).paragraphNum
        if (res.error) {
          setResultStatus(prev => {
            const newResultStatus = [...prev];
            newResultStatus[type] = 3;
            return newResultStatus
          })
          let errorMsg = '';
          switch (type) {
            case 1:
              errorMsg = '兴趣段落生成失败';
              break;
            case 2:
              errorMsg = '教育背景生成失败';
              break;
            case 3:
              errorMsg = '其他背景生成失败';
              break;
            case 4:
              errorMsg = '未来规划生成失败';
              break;
            default:
              errorMsg = '段落生成失败';
              break;
          }
          setResultErrorMsg(resultErrorMsg + '\n' + errorMsg)
        } else {
          setShowResult(true)
          setResultStatus(prev => {
            const newResultStatus = [...prev];
            newResultStatus[type] = 2;
            return newResultStatus
          })
          dispatch(saveFuncs[type](res.data.result))
        }
      })
    }).catch( err => {
      if (err.response.data.errorCode == 2) {
        dispatch(openSnackbar({
          message: intl.formatMessage({id: "Credit Inadequate"}),
          variant: 'alert',
          alert: { color: 'error' },
        }))
      }
    }).finally(() => {
      getCredit().then( res => {
        dispatch(
          setCredit(res.data.credit)
        )
      })
      if (resultStatus.includes(3)) {
        setTryAgain(true);
      }
    })
  }

  const genFinalPS = async () => {
    if (Object.values(basicInfo).includes('')) {
      setStatus({basicinfo: 5})
      dispatch(
        openSnackbar({
          message: intl.formatMessage({id:"Error Basic Info Missing"}),
          variant: 'alert',
          alert: { color: 'error' }
        })
      )
      return;
    };
    for(let [k, v] of Object.entries(parsed)) {
      if (v === '') {
        dispatch(
          openSnackbar({
            message: intl.formatMessage({id:"Error Para Missing"}),
            variant: 'alert',
            alert: { color: 'error' }
          })
        )
        return;
      }
    }
    setLoading(true)
    setShowResult(true)
    if (contentIDs[1] == '0') {
      await createPS();
    } else {
      setResultStatus(prev => {
        const newResultStatus = [...prev];
        newResultStatus[0] = 2;
        return newResultStatus
      })
      setFlag(2);
      setFlag2(2);
    }
    
  }

  const reGen = async () => {
    setFlag(1);
    setFlag2(1);
    setResultErrorMsg('');
    if (resultStatus[0] === 3) {
      await createPS();
    } else {
      const parsedKey = ['interest', 'academic', 'other', 'future']
      for(let i = 1; i <= 4; i++) {
        if (resultStatus[i] === 3) {
          generate(contentIDs[i], basicInfo, parsedKey[i], i).then( res => {
            setResultStatus(prev => {
              const newResultStatus = [...prev];
              newResultStatus[i] = 2;
              return newResultStatus
            })
            dispatch(saveFuncs[i](res.data.result))
          }).catch( err => {
            console.log(err)
            setResultStatus(prev => {
              const newResultStatus = [...prev];
              newResultStatus[i] = 3;
              return newResultStatus
            })
            let errorMsg = '';
            switch (i) {
              case 1:
                errorMsg = '兴趣段落生成失败';
                break;
              case 2:
                errorMsg = '教育背景生成失败';
                break;
              case 3:
                errorMsg = '其他背景生成失败';
                break;
              case 4:
                errorMsg = '未来规划生成失败';
                break;
              default:
                errorMsg = '段落生成失败';
                break;
            }
            setResultErrorMsg(resultErrorMsg + '\n' + errorMsg)
          })
        }
      }
    }
  }
  
  const handleClose = () => {
    setShowResult(false);
    navigate(`/ps/interest/${id}`)
  }

  const goPreview = () => {
    setOldChosen([0, 0, 0, 0, 0]);
    setChoosingRes(true);
    const reqs = [1, 2, 3, 4].map(v => saveChoice({choice: v, id: contentIDs[v]}).then(err => err))
    Promise.all(reqs).then(res => {
      res.forEach(res => {
        setShowResult(false);
        setChoosingRes(false);
        navigate(`/ps/preview/${id}`)
      })
    }).catch( err => {
      setResultErrorMsg(toString(err))
      setChoosingRes(false);
    })
  }

  const handleInputChange = (e, flag) => {
    setParsed(prev => {
      const now = Object.assign({}, prev);
      now[flag] = e.target.value;
      return now;
    })
  }

  useEffect(() => {
    if (contentIDs[1] != '0') {
      setFlag(2);
    }
  }, [contentIDs])

  useEffect(() => {
    if (flag === 2 && flag2 === 2) {
      genParas();
    }
  }, [flag, flag2])

  const ResultStatusIcon = ({type}) => {
    return (
      <ListItemIcon>
        <CircularProgress size='19px' style={{display: resultStatus[type] == 1 ? 'block': 'none'}} />
        <Done style={{display: resultStatus[type] == 2 ? 'block': 'none'}} />
        <Error style={{display: resultStatus[type] == 3 ? 'block': 'none'}} />
      </ListItemIcon>
    )
  }

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={10} md={100}>
        {initing ?
          <MainCard title={<Skeleton />} style={{ marginBottom: '16px' }}>
            <Grid container warp="nowarp" spacing={3}>
              <Grid item xs>
                <Skeleton />
                  <Skeleton />
              </Grid>
              <Grid item xs={12}>
                <Skeleton />
                <Skeleton />
              </Grid>
            </Grid>
          </MainCard>
        : 
          <div>
            {/* Title */}
            <MainCard>
              <div style={{fontSize: '20px'}}>{intl.formatMessage({id: 'Template'})}</div>
            </MainCard>
            {/* Upload PS */}
            <MainCard title={intl.formatMessage({id: 'Upload PS'})} style={{marginTop: '4px', marginBottom: '4px'}}>
              <p>{intl.formatMessage({id: 'Support Type Description'})}</p>
              <FileUploader onUpload={parsePS} />
            </MainCard>
            {/* Basic Information */}
            <MainCard title={intl.formatMessage({id: 'Basic Information'})} style={{marginTop: '4px', marginBottom: '4px'}}>
                <Grid container spacing={3} columns={2}>
                    {/* 本科学校 */}
                    <Grid item xs={1}>
                        <TextField
                            fullWidth
                            required
                            helperText={ basicInfo.currentSchool ? '' : intl.formatMessage({id:"Required"}) }
                            label={intl.formatMessage({id:"Current School"})}
                            type={intl.formatMessage({id:"Current School"})}
                            value={basicInfo.currentSchool}
                            onChange={(e) => {handleEdit("currentSchool", e.target.value)}}
                        />
                    </Grid>
                    
                    {/* 申请学校 */}
                    <Grid item xs={1}>
                        <TextField
                            fullWidth
                            required
                            helperText={ basicInfo.applySchool ? '': intl.formatMessage({id:"Required"})}
                            label={intl.formatMessage({id:"Applying School"})}
                            type={intl.formatMessage({id:"Applying School"})}
                            value={basicInfo.applySchool}
                            onChange={(e) => {handleEdit("applySchool", e.target.value)}}
                        />
                    </Grid>
                    {/* 本科专业 */}
                    <Grid item xs={1}>
                        <TextField
                            fullWidth
                            required
                            helperText={ basicInfo.currentMajor ? '': intl.formatMessage({id:"Required"})}
                            label={intl.formatMessage({id:"Current Major"})}
                            type={intl.formatMessage({id:"Current Major"})}
                            value={basicInfo.currentMajor}
                            onChange={(e) => {handleEdit("currentMajor", e.target.value)}}
                        />
                    </Grid>
                    {/* 申请专业 */}
                    <Grid item xs={1}>
                        <TextField
                            fullWidth
                            required
                            helperText={ basicInfo.applyMajor ? '': intl.formatMessage({id:"Required"})}
                            label={intl.formatMessage({id:"Applying Major"})}
                            type={intl.formatMessage({id:"Applying Major"})}
                            value={basicInfo.applyMajor}
                            onChange={(e) => {handleEdit("applyMajor", e.target.value)}}
                        />
                    </Grid>
                    {/* 本科成绩 */}
                    <Grid item xs={1}>
                        <TextField
                            fullWidth
                            required
                            helperText={ basicInfo.currentGPA ? '': intl.formatMessage({id:"Required"})}
                            label={intl.formatMessage({id:"Current Score"})}
                            type={intl.formatMessage({id:"Current Score"})}
                            value={basicInfo.currentGPA}
                            onChange={(e) => {handleEdit("currentGPA", e.target.value)}}
                        />
                    </Grid>
                    
                </Grid>
            </MainCard>
            {/* Parse Result */}
            <MainCard title={intl.formatMessage({id: 'Parse Result'})} style={{marginTop: '4px', marginBottom: '4px'}}>
                <Grid item xs>
                    <p style={{fontSize: '16px'}}>兴趣段落</p>
                    <TextField
                        fullWidth
                        multiline
                        rows={10}
                        placeholder={intl.formatMessage({id: "Interest HelperText"})}
                        label={intl.formatMessage({id: "Interest Label"})}
                        focused
                        value={parsed.interest}
                        onChange={(e) => handleInputChange(e, 'interest')}
                    />
                </Grid>
                <br />
                <Grid item xs>
                    <p style={{fontSize: '16px'}}>教育背景</p>
                    <TextField
                        fullWidth
                        multiline
                        rows={10}
                        placeholder={intl.formatMessage({id: "Education HelperText"})}
                        label={intl.formatMessage({id: "Education Label"})}
                        focused
                        value={parsed.academic}
                        onChange={(e) => handleInputChange(e, 'academic')}
                    />
                </Grid>
                <br />
                <Grid item xs>
                    <p style={{fontSize: '16px'}}>其他背景</p>
                    <TextField
                        fullWidth
                        multiline
                        rows={10}
                        placeholder={intl.formatMessage({id: "Other HelperText"})}
                        label={intl.formatMessage({id: "Other Label"})}
                        focused
                        value={parsed.other}
                        onChange={(e) => handleInputChange(e, 'other')}
                    />
                </Grid>
                <br />
                <Grid item xs>
                    <p style={{fontSize: '16px'}}>未来规划</p>
                    <TextField
                        fullWidth
                        multiline
                        rows={10}
                        placeholder={intl.formatMessage({id: "Future HelperText"})}
                        label={intl.formatMessage({id: "Future Label"})}
                        focused
                        value={parsed.future}
                        onChange={(e) => handleInputChange(e, 'future')}
                    />
                </Grid>
            </MainCard>
            {/* Extra Part */}
            <MainCard title={intl.formatMessage({id: 'Extra Part'})} style={{marginTop: '4px', marginBottom: '4px'}}>
              <p style={{fontSize: '16px'}}>{intl.formatMessage({id: 'PS Words'})}</p>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <TextField placeholder={intl.formatMessage({id: "Minimum"})} />
                <div style={{marginLeft: '3px', marginRight: '3px'}}>——</div>
                <TextField placeholder={intl.formatMessage({id: "Maximum"})} />
              </div>
              <p style={{fontSize: '16px'}}>{intl.formatMessage({id: 'PS Requirements'})}</p>
              <TextField
                  fullWidth
                  multiline
                  rows={5}
                  placeholder={intl.formatMessage({id: "PS Requirements"})}
              />
            </MainCard>
            {/* Submit */}
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
              <LoadingButton variant="contained" loading={loading} type="submit" onClick={genFinalPS} >
                <FormattedMessage id="Generate PS" />
              </LoadingButton>
            </div>
            {/* Result */}
            <Dialog open={showResult} onClose={handleClose} >
                <DialogTitle>文书生成</DialogTitle>
                <DialogContent>
                  <div style={{textAlign: 'center'}}>
                <div style={{display: 'flex', marginTop: '3px', marginBottom: '3px', justifyContent: 'space-evenly'}}>
                  <p style={{fontSize: '16px', margin: 3}}>创建文书</p>
                  <ResultStatusIcon type={0} />
                </div>
                <div style={{display: 'flex', marginTop: '3px', marginBottom: '3px', justifyContent: 'space-evenly'}}>
                  <p style={{fontSize: '16px', margin: 3}}>兴趣段落</p>
                  <ResultStatusIcon type={1} />
                </div> 
                <div style={{display: 'flex', marginTop: '3px', marginBottom: '3px', justifyContent: 'space-evenly'}}>
                  <p style={{fontSize: '16px', margin: 3}}>教育背景</p>
                  <ResultStatusIcon type={2} />
                </div>
                <div style={{display: 'flex', marginTop: '3px', marginBottom: '3px', justifyContent: 'space-evenly'}}>
                  <p style={{fontSize: '16px', margin: 3}}>其他背景</p>
                  <ResultStatusIcon type={3} />
                </div>
                <div style={{display: 'flex', marginTop: '3px', marginBottom: '3px', justifyContent: 'space-evenly'}}>
                  <p style={{fontSize: '16px', margin: 3}}>未来规划</p>
                  <ResultStatusIcon type={4} />
                </div>
                  </div>
                </DialogContent>
                <div style={{textAlign: 'center'}}>
                  <div>{resultErrorMsg}</div>
                </div>
                <DialogActions style={{display: resultStatus.every(ele => ele === 2) ? 'block': 'none'}}>
                  <Button onClick={handleClose}>自行选择结果段落</Button>
                  <Button onClick={goPreview}>帮我选，直接去全文预览</Button>
                </DialogActions>
                <DialogActions style={{display: tryAgain ? 'block': 'none'}}>
                  <LoadingButton onClick={reGen} loading={choosingRes}>{resultStatus[0] === 3 ? '重新创建文书' : '重新生成失败段落'}</LoadingButton>
                </DialogActions>
            </Dialog>
          </div>
        }
      </Grid>
    </Grid>
);
};

export default UploadTemplate;
