import axiosInstance from './config'
import axios from 'axios'
const getRegOTP = (email, type) => {
    return axiosInstance.post(`/register/sendVerificationCode`, {email: email, type: type}, {
        headers: {
            'Content-Type': 'Application/json'
        }
    });
}

const verifyOTP = (email, otp) => {
    return axiosInstance.post(`/auth/verifyCode`, {email: email, userInput: otp}, {
        headers: {
            'Content-Type': 'Application/json'
        }
    })
}

const login = (type, email, password) => {
    return axiosInstance.post(`/auth/login`, {type: type, email: email, password: password}, {
        headers: {
            "Content-Type": "Application/json"
        }
    })
}


const register = (email, otp, password) => {
    return axiosInstance.post(`/auth/register`, {email: email, otp: otp, password: password}, {
        headers: {
            "Content-Type": "Application/json"
        }
    })
}

const logout = () => {
    return axiosInstance.post(`/auth/logout`)
}

const checkLogin = () => {
    return axiosInstance.get(`/auth`)
}

export {getRegOTP, login, register, logout, checkLogin}