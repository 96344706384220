import MainLayout from 'layout/MainLayout';
import VideoPage from 'views/guidance/video/video'
import Manage from 'views/manage'
import BasicInfo from 'views/ps/basic_info'
import Interest from 'views/ps/interest'
import Education from 'views/ps/education'
import Other from 'views/ps/other'
import Plan from 'views/ps/plan'
import Preview from 'views/ps/preview';

import AuthGuard from 'utils/route-guard/AuthGuard';
import UploadTemplate from 'views/ps/upload_pscln';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
        // <MainLayout />
    ),
    children: [
        {
            path: '/guidance/video',
            element: <VideoPage />
        },
        {
            path: '/ps/addtemplate',
            element: <UploadTemplate />
        },
        {
            path: '/ps/manage',
            element: <Manage />
        },
        {
            path: '/ps/basicinfo/:psID',
            element: <BasicInfo />
        },
        {
            path: '/ps/interest/:psID',
            element: <Interest />
        },
        {
            path: '/ps/education/:psID',
            element: <Education />
        },
        {
            path: '/ps/other/:psID',
            element: <Other />
        },
        {
            path: '/ps/plan/:psID',
            element: <Plan />
        },
        {
            path: '/ps/preview/:psID',
            element: <Preview />
        }
    ]
};

export default MainRoutes;
