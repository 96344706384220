import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { FormattedMessage, useIntl } from 'react-intl';

function VideoPage() {
    const intl = useIntl();
    return (
        <MainCard title={intl.formatMessage({id: "Guidance Video"})} style={{ marginBottom: '16px' }}>
            <Paper elevation={3}>
                <video controls width="100%">
                    <source src="https://admit-gen-bucket.s3.ap-east-1.amazonaws.com/AdmitGenIntro.mp4" type="video/mp4" />
                    <track src="https://admit-gen-bucket.s3.ap-east-1.amazonaws.com/AdmitGenIntro.mp4" kind="captions" />
                    Your browser does not support the video tag.
                </video>
                {/* <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                    <iframe
                        title="YouTube Video"
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/8Fvn4as79OA?si=6OaaWqquzEId6_yM"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{ position: 'absolute', top: 0, left: 0 }}
                    />
                </div> */}
            </Paper>
        </MainCard>
    );
}

export default VideoPage;
