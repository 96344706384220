import { useDispatch } from 'store';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useState, useEffect } from 'react';
// material-ui
import { Button, Grid, Stack, TextField, Skeleton } from '@mui/material';
import { gridSpacing } from 'store/constant';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { openSnackbar } from 'store/slices/snackbar';
import submitBasicInfo from 'api/basicinfo'
import { saveBasicInfo, setContentID, setContentIDs } from 'store/slices/psData';
import { LoadingButton } from '@mui/lab';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getPS } from 'api/ps';
import { store } from 'store';

// ==============================|| FORM VALIDATION - INSTANT FEEDBACK FORMIK  ||============================== //

const BasicInfo = () => {
  const [fillingStatus, setStatus] = useOutletContext();
  const [basicInfo, setBasicInfo] = useState({
    currentSchool: '',
    currentMajor: '',
    applySchool: '',
    applyMajor: '',
    currentGPA: ''
  });
  const [loading, setLoading] = useState(false);
  const [initing, setIniting] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const intl = useIntl();

  const user = useSelector(state => state.account).user;
  const title = useSelector(state => state.psData).title;
  const id = useSelector(state => state.psData).id;
  const basicInfoId = useSelector(state => state.psData).contentIDs[0];

  const savedBasicInfo = useSelector(state => state.psData).basicData
  // get currentID from route path and set redux

  const handleButtonClick = () => {
    if (Object.values(basicInfo).includes('')) {
      setStatus({basicinfo: 5})
      dispatch(
        openSnackbar({
          message: intl.formatMessage({id:"Error Basic Info Missing"}),
          variant: 'alert',
          alert: { color: 'error' }
        })
      )
      return;
    };
    setStatus({basicinfo: 2})
    setLoading(true);
    submitBasicInfo({
      currentSchool: basicInfo.currentSchool, 
      currentGPA: basicInfo.currentGPA,
      currentMajor: basicInfo.currentMajor,
      applySchool: basicInfo.applySchool,
      applyMajor: basicInfo.applyMajor
    }, basicInfoId, user, title).then( res => {
      if (res.data.data._id) {
        setStatus({basicinfo: 4})
        dispatch(
          openSnackbar({
              message: intl.formatMessage({id: "Basic Info Submit Success"}),
              variant: 'alert',
              alert: { color: 'success' },
          })
        );
        // sessionStorage.setItem("basicInfo", JSON.stringify(basicInfo));
        dispatch(saveBasicInfo(basicInfo));
        navigate(`/ps/interest/${id}`);
      } else {
        setStatus({basicinfo: 5})
        dispatch(
          openSnackbar({
            message: intl.formatMessage({id: "Submit Failed"}),
            variant: 'alert',
            alert: { color: 'error' },
          })
        );
      }
    }).catch( err => {
      setStatus({basicinfo: 6})
      dispatch(
        openSnackbar({
          message: intl.formatMessage({id: "Submit Failed"}) + err,
          variant: 'alert',
          alert: { color: 'error' },
        })
      );
    }).finally(() => {
      setLoading(false);
    })
  }

  const handleEdit = (key, value) => {
    const newBasicInfo = {
      ...basicInfo,
    }
    newBasicInfo[key] = value;
    setBasicInfo(newBasicInfo);
    setStatus({basicinfo: 1});
  }

  useEffect(() => {
    getPS(id).then( res => {
      for(let [k, v] of Object.entries(res.data.basicInfo)) {
        if (k in basicInfo) {
          basicInfo[k] = v;
        }
      }
      dispatch(saveBasicInfo(basicInfo))
      dispatch(setContentID({index: 0, contentID: res.data.basicInfo._id}))
    }).catch( err => {
      dispatch(
        openSnackbar({
          message: intl.formatMessage({id: "Data Sync Failed"}) + err,
          variant: 'alert',
          alert: { color: 'error' },
        })
      );
    }).finally(() => setIniting(false))
  }, [])

  useEffect(() => {
    // sessionStorage.setItem("basicInfo", JSON.stringify(basicInfo));
    // no need to sync basicinfo to backend
    setBasicInfo({
      currentSchool: '',
      currentMajor: '',
      applySchool: '',
      applyMajor: '',
      currentGPA: ''
    })
    setBasicInfo(savedBasicInfo)
  }, [savedBasicInfo])

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={10} md={100}>
        {initing ?
          <MainCard title={<Skeleton />} style={{ marginBottom: '16px' }}>
            <Grid container warp="nowarp" spacing={3}>
              <Grid item xs>
                <Skeleton />
                  <Skeleton />
              </Grid>
              <Grid item xs={12}>
                <Skeleton />
                <Skeleton />
              </Grid>
            </Grid>
          </MainCard>
        : 
          <MainCard title={intl.formatMessage({id: 'Basic Information'})}>
              <Grid container spacing={3} columns={2}>
                  {/* 本科学校 */}
                  <Grid item xs={1}>
                      <TextField
                          fullWidth
                          required
                          helperText={ basicInfo.currentSchool ? '' : intl.formatMessage({id:"Required"}) }
                          label={intl.formatMessage({id:"Current School"})}
                          type={intl.formatMessage({id:"Current School"})}
                          value={basicInfo.currentSchool}
                          onChange={(e) => {handleEdit("currentSchool", e.target.value)}}
                      />
                  </Grid>
                  
                  {/* 申请学校 */}
                  <Grid item xs={1}>
                      <TextField
                          fullWidth
                          required
                          helperText={ basicInfo.applySchool ? '': intl.formatMessage({id:"Required"})}
                          label={intl.formatMessage({id:"Applying School"})}
                          type={intl.formatMessage({id:"Applying School"})}
                          value={basicInfo.applySchool}
                          onChange={(e) => {handleEdit("applySchool", e.target.value)}}
                      />
                  </Grid>
                  {/* 本科专业 */}
                  <Grid item xs={1}>
                      <TextField
                          fullWidth
                          required
                          helperText={ basicInfo.currentMajor ? '': intl.formatMessage({id:"Required"})}
                          label={intl.formatMessage({id:"Current Major"})}
                          type={intl.formatMessage({id:"Current Major"})}
                          value={basicInfo.currentMajor}
                          onChange={(e) => {handleEdit("currentMajor", e.target.value)}}
                      />
                  </Grid>
                  {/* 申请专业 */}
                  <Grid item xs={1}>
                      <TextField
                          fullWidth
                          required
                          helperText={ basicInfo.applyMajor ? '': intl.formatMessage({id:"Required"})}
                          label={intl.formatMessage({id:"Applying Major"})}
                          type={intl.formatMessage({id:"Applying Major"})}
                          value={basicInfo.applyMajor}
                          onChange={(e) => {handleEdit("applyMajor", e.target.value)}}
                      />
                  </Grid>
                  {/* 本科成绩 */}
                  <Grid item xs={1}>
                      <TextField
                          fullWidth
                          required
                          helperText={ basicInfo.currentGPA ? '': intl.formatMessage({id:"Required"})}
                          label={intl.formatMessage({id:"Current Score"})}
                          type={intl.formatMessage({id:"Current Score"})}
                          value={basicInfo.currentGPA}
                          onChange={(e) => {handleEdit("currentGPA", e.target.value)}}
                      />
                  </Grid>
                  <Grid item xs={12}>
                      <Stack direction="row" justifyContent="flex-end">
                              <LoadingButton loading={loading || fillingStatus.basicinfo === 2} variant="contained" type="submit" onClick={handleButtonClick} >
                              <FormattedMessage id="Submit" />
                              </LoadingButton>
                      </Stack>
                  </Grid>
              </Grid>
          </MainCard>
        }
      </Grid>
    </Grid>
);
};

export default BasicInfo;
