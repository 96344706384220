import { memo, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

// project imports
import menuItem from 'menu-items';
import NavGroup from './NavGroup';
import useConfig from 'hooks/useConfig';

import LAYOUT_CONST from 'constant';
import { HORIZONTAL_MAX_ITEM } from 'config';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({status}) => {
    const theme = useTheme();
    const { layout } = useConfig();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const splitPath = window.location.pathname.split('/')
    const currentID = useSelector(state => state.psData).id || splitPath.length === 4 ? splitPath[3]: '';

    const [menu, setMenu] = useState(menuItem);

    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;

    let lastItemIndex = menuItem.items.length - 1;
    let remItems = [];
    let lastItemId;

    if (lastItem && lastItem < menuItem.items.length) {
        lastItemId = menuItem.items[lastItem - 1].id;
        lastItemIndex = lastItem - 1;
        remItems = menuItem.items.slice(lastItem - 1, menuItem.items.length).map((item) => ({
            title: item.title,
            elements: item.children
        }));
    }

    useEffect(() => {
        setMenu((prev) => {
            let newMenu = Object.assign({}, prev);
            for(let group of newMenu.items) {
                for(let child of group.children) {
                    child.status = status[child.id]
                }
            }

            return newMenu
        })
    }, [status])

    useEffect(() => {
        setMenu((prev) => {
            let newMenu = Object.assign({}, prev);
            for(let group of newMenu.items) {
                if (group.id === 'ps' && currentID) {
                    for(let child of group.children) {
                        if (child.url.split('/').length === 4) {
                            child.url = child.url.slice(0, child.url.lastIndexOf('/')+1) + currentID;
                        } else {
                            child.url += `/${currentID}`
                        }
                    }
                }
            }

            return newMenu
        })
    }, [currentID])

    return (
        <>
            {menu.items.slice(0, lastItemIndex + 1).map((item) => {
                switch (item.type) {
                    case 'group':
                        return <NavGroup key={item.id} item={item} lastItem={lastItem} remItems={remItems} lastItemId={lastItemId} />;
                    default:
                        return (
                            <Typography key={item.id} variant="h6" color="error" align="center">
                                Menu Items Error
                            </Typography>
                        );
                }
            })}
        </>
    )
};

export default MenuList;
