import { Grid } from '@mui/material';
import SkeletonTotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';

// project imports

import MainCard from 'ui-component/cards/MainCard';

// ==============================|| SAMPLE PAGE ||============================== //

const SkeletonCard = () => (
    <Grid spacing={3}>
        <Grid item xs={12} style={{ fontWeight: 'bold' }}>
            <SkeletonTotalIncomeCard />
        </Grid>
        <Grid item xs={12} style={{ fontWeight: 'bold' }}>
            <SkeletonTotalIncomeCard />
        </Grid>
        <Grid item xs={12} style={{ fontWeight: 'bold' }}>
            <SkeletonTotalIncomeCard />
        </Grid>
        <Grid item xs={12} style={{ fontWeight: 'bold' }}>
            <SkeletonTotalIncomeCard />
        </Grid>
        <Grid item xs={12} style={{ fontWeight: 'bold' }}>
            <SkeletonTotalIncomeCard />
        </Grid>
    </Grid>
);

export default SkeletonCard;
