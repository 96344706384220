import { Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import MainCard from 'ui-component/cards/MainCard';
import Accordion from 'ui-component/extended/Accordion';

const Plan = [
    {
        id: 'basic1',
        defaultExpand: false,
        title: 'Oxford Offer',
        content:
            'The MSC program in XXXXX at the University of Oxford is my first choice. As the Department of XXXXX at Oxford provides interdisciplinary learning, I could share thoughts with students from different backgrounds and gain a global and multidisciplinary perspective. I am eager to take XXXXX where I could learn to craft powerful sociological arguments and theories. In addition, I expect to gain rigorous academic trainings and do research in XXXXXXX. Specifically, I want to earn from Professor XXXXXX research interests are gender inequalities in the family and gender division of labor in XXXXXX. I hope to do research under the guidance of Professor XXXXXX who specialises in gender inequalities and family balance, and Professor XXXXXX who focuses on gender relations and family life in contemporary XXXXXX. I hope to learn more about the changes of social thoughts and movements so as to conduct research in comparing Western families with Chinese families and to study how different cultures shape the gender roles. I believe that the MSC in XXXXX at Oxford will be an ideal platform for me to study the gender inequality issue. My deep interest in this area, relevant experience as well as my unique perspective would make me a valuable asset to the program. XXXXX major is international relations, I have the global outlook and sound skills explore and study the current affairs around the world. As a XXXXX, I have been brought up with traditional XXXXX wisdom and live in a rapid changing society, which could help me express opinions from different standpoints and analyzing conflicts from different perspectives. My objective for graduate studies is to combine my future sociology learning at Oxford, my academic skills of international relations and my different thinking patterns, hopefully making a difference to the society. I believe that Oxford can offer extraordinary opportunities for me.'
    },
    {
        id: 'basic2',
        title: 'LSE Offer',
        content:
            'After finishing postgraduate studies, with a better understanding of globalization and the specific area I have mentioned above, I would like to set my career focus on doing marketing, advertising or public relations related jobs, where I can help those transnational corporations successfully enter XXXXXX market based on the unique XXXXX culture with a maturing technology. I’m also particularly interested in enhancing understanding between people, societies and countries, and reducing misunderstandings between eastern and western societies caused by cultural differences. In summary, with its specialization in communication, this unique XXXXX program not only provides me with the chance to learn XXXXXX theoretically, but offers me many opportunities to develop my practical skills. I believe my employability will be further enhanced, since the universities highlight verbal and written communication skills acquired by group discussions and presenting works. The location also serves as an important reason, for XXXXX and XXXXX, the two most globalized countries, have the longest history of media Industry. There I would systematically learn the evolutional history of the media industry and the latest trend of its development, as well as observe the interaction between multi-cultural communication and economic transnationalism in a globalized context. I would relish this opportunity to be further educated in the world media center.'
    },
    {
        id: 'basic3',
        title: 'UCL Offer',
        content:
            'When it comes to my future, I intend to work in a consultancy company and engage in geographic data mining, analysis and visualisation so that I can help enterprises and industries to find the direction of future development. To realize my goal, I need the support of your rigorous training. The MSc XXXXXX programme is taught by the leading academics at University College London, which will undoubtedly offer me the best environment to grow up and gain expertise. What appealing to me the most is your programme content, which will develop my professional knowledge and skills to deal with the problems at the interface of social sciences, computing and data analysis. Among them, I am particularly interested in XXXXXX and XXXXXXX. The former allows me to deepen my knowledge of Python and master the application of Python in data analysis and machine learning for social and spatial networks. The latter then helps me understand the principles of spatial phenomena, as well as the application of R, QGIS and ArcMap in spatial data acquisition and analysis. In conclusion, your guidance will help me scale new heights in my future work. I cannot wait to join you. '
    },
    {
        id: 'basic4',
        title: 'HKU Offer',
        content:
            'Currently, I am running a music broadcast series as the initial phase of my plan, which is mainly three-fold in steps: establish a music promotion channel, search for new forms of live shows, and cultivate a community of musicians and audience. I believe the experience in XXXXXX will be a boost to my goal. I cannot wait to be a part of HKU XXXXX, overcoming the challenges and enjoying the process. '
    },
    {
        id: 'basic5',
        title: 'KCL Offer',
        content:
            'These experiences outside the classroom have confirmed my goal of examining the cultural business in a transnational context, where the city of London looms large. I have always fascinated by the cultural ambiance of London since the first time I read Sherlock Homes. I even conducted a thorough case study on the TV series of the novel in my undergraduate dissertation. Located at my favorite cultural center, King’s College London offers an unparalleled M.A. program in XXXXXX when compared with other institutions. Specifically, I am interested in working with XXXXXXX, whose expertise in expressions of digital culture can be invaluable. Furthermore, I also want to participate in programs co-organized with world class-institutions like XXXXX that provide rare insider’s perspective. I intend to refine my skills in understanding theoretical frameworks, conducting interdisciplinary research, and improving industrial analysis in my graduate studies. With these skillsets, I want to become an excellent associate in the department of marketing strategies of cultural industry to promote great creative works after graduation'
    }
];

const Other = [
    {
        id: 'basic1',
        defaultExpand: false,
        title: 'LSE Offer',
        content:
            'I am particularly interested in studying the confluence of media and public communication, and the flows of influence between the two. I have experience of evaluating crisis communication cases in both political and commercial fields, of designing and implementing a non-smoking health communication campaign, of public opinion surveys through XXXX system, and of redesigning the news web portal of a municipal government to better meet the needs of the public. These real world cases demonstrate the potential role of sophisticated, advanced communication methods in building and sustaining prosperous and cohesive societies. Developing a deep understanding of today’s media environment allows for genuine insight and innovation in our society. While it is clear that much public communication today relies on pre-made platforms, only with a true understanding of the structural role of communication as a social psychological process can one hope to fully grasp the intricacies of public communication. I have experience that will allow me to contextualise the conceptual frameworks of analysis studied in the Msc. I have internship experiences at a radio station, a sports daily newspaper, a news web portal, an Internet firm as well as a Fortune 500 company. I have also seen first-hand the deployment of media as a strategy of public influence while interning at a XXXXX independent public relations and consulting firm. This internship gave me exposure to brand marketing, and corporate and digital communications as well as crystallizing my career aspirations.'
    },
    {
        id: 'basic2',
        title: 'UCL Offer',
        content:
            'When not engaged in academic pursuits, I have utilised my spare time to undertake a variety of internships which have afforded me tremendous experience. Firstly, I worked in the XXXXX Department of a media company as a XXXXX. In this position, I conducted an investigation into the international animation industry. I later produced a 60-page visual report that analysed the background and operations of the voice actor industry, which included predictions based on China’s approach and the current market environment. As well as permitting me direct exposure to the creative and digital industry, this experience enhanced my data organisation and analysis skills. I then interned at XXXXXX where I performed various aspects of PR work, such as providing decision-making advice to clients on the issue of operation and communication processes. In this position, I came to understand the need for transnational companies to pay attention to political and cultural sensitivities if they hope to succeed, which in the context of XXXX often means collaborating with local Media personalities and accounts. '
    },
    {
        id: 'basic3',
        title: 'UCL Offer',
        content:
            'To deepen my understanding of XXXXX, I worked as a data trainee in XXXXX, a consulting company. During the period, I engaged in data extraction with Python, natural language processing, and the analysis of interview contents, which helped to filter the interviewees’ true attitude towards products from a large number of texts accurately. In my opinion, the attitude of consumers is a crucial point that the marketing department should pay attention to. We might find it difficult to draw an effective conclusion with pure qualitative analysis due to the subjective influence of analyzers. However, the analysis will be more persuasive if we can bring computer technology into the research of humanities. I have deeply felt the charm and potential of digital humanities in the internship, which further inspired me to learn it professionally at the graduate level. '
    },
    {
        id: 'basic4',
        title: 'NTU Offer',
        content:
            'In terms of leisure time, the concern for society often motivates me to take an active part in public activities. The most impressive one was the donation to a hope school in XXXXXX, a remote mountain area of China. After reading a tiny piece of advertisement in the newspapers, I made a speech in my college to raise money for those pupils, then turned the XXXXX into piles of books and stationeries in the provincial city. Though it had been a long and tough way to get into that place, I found all those difficulties worthy when the children smiled at me with lights in their eyes. I took some photos and showed them to my peers as soon as I returned. Looking back to the words that brought me there, I realized the importance of media, which is not only a message carrier, but also a hope carrier.'
    },
    {
        id: 'basic5',
        title: 'HKUST Offer',
        content:
            'To truly experience the analysis and application of public policies in the business environment, I mainly participated in two internships. During my time at XXXXXX, I participated in a research project on Chinese tobacco policy commissioned by a foreign company. I reviewed most of the literature and connected researchers‘ opinions concerning whether the government should open salt, gas, fossil oil market. To estimate the importance of different research scholars in policymaking, I detailed and concluded the procedure of forming "XXXXXX" and locate the participation time and impact of the researchers involved in the policy development process. In the second internship, I participated in XXXX consulting project for the government: XXXXXXXX Report. In this project, we hope to provide the government with accurate and sufficient information to support its decision-making. I was responsible for collecting data on issues as a result of urbanisation, the market share of smart city and governments revenue around the world, and writing case study analysis of XXXXX leading cities. Through the above two internships, I perceived the difference between the commercial field in the analysis and application of public policy and the academic one: public policy analysis in the business environment pays more attention to practicality and impact on specific enterprises and specific projects. Also, I also gained the pleasure and sense of accomplishment of completing the business public policy analysis project and strengthened my determination to embark a career in public policy analysis'
    }
];


const Interest = [
    {
        id: 'basic1',
        defaultExpand: false,
        title: 'Oxford Offer',
        content:
            'My passion for sociology stems from XXXXX which discusses the unfair treatment of women throughout history. In this process, I found myself intrigued with the study of societymain about gender inequalities. In the historically patriarchal society of China, efforts toward gender equality have been met with great resistance. Thus, I am interested in gender study within the sociology program, with the ambition of serving in NGOS after graduation to promote gender equality. I firmly believe that XXXXX at University of Oxford is perfect for my graduate education and future career. '
    },
    {
        id: 'basic2',
        title: 'Cambridge Offer',
        content:
            'I encountered algorithms and data structures through the book Competitive Programming 3 by Steven and Felix Halim. While reading it, I was most impressed by Dynamic Programming paradigm. In a 0-1 knapsack question, I used it to partition the problem into sub-structures and solve via a transition formula. This idea later became part of my logical thinking. The optimisation of Union-Find Disjoint Sets such as union by rank and path compression attracted me as well, both of them helped me comprehend the set theory behind it better. Earning a gold medal in the XXXXX increased my ambition to work on the development of more efficient algorithms which may assist society by solving currently intractable problems'
    },
    {
        id: 'basic3',
        title: 'LSE Offer',
        content:
            'As a student majoring in XXXXX, I have been aware of the power of media and communication in today’s increasing globalized world. In international commerce, media has been used in industries such as fashion, and deeply influences consumer’s purchasing decisions. For this reason, I initially viewed media largely as a tool for profit-marketing, but later realized its potential for altruistic uses. Now I would like to apply to MSc XXXXXX program with my goal of combining an expertise in media with humanitarian projects.'
    },
    {
        id: 'basic4',
        title: 'UCL Offer',
        content:
            'My interest in XXXXX was developed in the course XXXXX in my undergraduate study. In the final assessment, I made publicity for XXXXXX, a kind of intangible cultural heritage in China, on new media platforms. In addition to communicating with the inheritor, producing and publishing videos on the official WeChat account, I also collected the backstage data to conclude the reading peak and the age and gender of main readers. Through data analysis, I suggested releasing media content at eight o’clock in the evening and connecting its character with females’ hobbies, which had been adopted and implemented by the inheritor. This was my first time to bring data science into the field of cultural heritage, which soon aroused my keen interest. Currently, data science is widely applied in the Internet industry. However, it can also be quite beneficial to the research of the humanities areas like the protection and inheritance of cultural heritage. Taking my enthusiasm and future career into account, I am determined to apply for your programme.'
    },
    {
        id: 'basic5',
        title: 'HKU Offer',
        content:
            'At the moment that I became aware that media can change our cities, I was sitting in a hall. The realisation was sparked by a diploma project I had just listened to on digitalising XXXXXX using new media technologies that had sought to achieve a balance between art and profit. Before this, the professors in the School of XXXXXX have repeatedly reminded us that the media is not simply mean prints, broadcasts or webpages, but, more importantly, a way to provide a creative resolution for society and culture.'
    },
    {
        id: 'basic6',
        title: 'NTU Offer',
        content:
            'I was fascinated by the vitality of Singapore the first time seeing a propaganda on the plane several years ago, which showed an integration of different cultures in this country. As an applicant for the Master of XXXXX in Nanyang Technological University, I believe the diversified atmosphere will nurture my talents and provide an ideal environment for me to study in this major. Besides, such practical or inspiring courses as the XXXXXX and the XXXXXX attract me as well. To be qualified for the profession, I suppose the most important trait would be the spirit of exploration, and my natural curiosity along with the thirst for knowledge always drive me to explore the world from work, life to culture. The high-quality and strict education of XXXXXX in XXXXX University trained my logical thoughts and ability for analysis, which have played an important role in the work of energy management after I entered XXXXX.'
    }
];

const Education = [
    {
        id: 'basic1',
        defaultExpand: false,
        title: 'Oxford Offer',
        content:
            'My interest on sociology began to focus on XXXXXXX because of the Women Project I participated in, which originated from the XXXXX. Female students as well as professors studied and discussed the disadvantaged situation of women and built our confidence as females. The most impressive task was that we were asked by Professor XXXXX to self-examine our naked body in front of the mirror and write down 10 favorite parts of our own body. For the first time, I have had the chance to appreciate my body in a positive way instead of holding grudges about my flaws. The purpose of this task is to rid us of gender stereotypes about women‘s bodies. I also helped to raise our awareness of gender equality through delivering speeches and presenting posters on campus.'
    },
    {
        id: 'basic2',
        title: 'LSE Offer',
        content:
            'During last exchanging semester in XXXXX, I conducted a case study at XXXXX course about whaling in Japan, a universally controversial tradition. Based on my assumption that the controversy lied in different cultural images of the animal, I did some relevant research and interviewed some students and teachers from XXXXX, XXXXXX, and some other countries. Surprisingly, with careful reasoning, most XXXXX students could understand this tradition. Yet after conducting the case study, I realized that even though certain materials explaining whaling are easy to be found on the internet, many subjects would still hold their opinions until they are forced to know the reason for whaling. Certain stereotype always exists however the methods are tried, which intrigues me to further explore the way to reduce cultural barriers through systematically studying global communication.'
    },
    {
        id: 'basic3',
        title: 'UCL Offer',
        content:
            'Majoring in XXXXXX, I have been equipped with some computer skills that can be used in my future research. By attending XXXXX, I acquired the techniques of Dreamweaver and JavaScript, with which I produced a webpage to introduce the development history of the advertising industry and scored 93/100 in the final assessment. In XXXXX, I made publicity for XXXXX, a kind of intangible cultural heritage in China, on new media platforms. In addition to communicating with the inheritor, producing and publishing videos on the official WeChat account, I also collected the backstage data to conclude the reading peak and the locations and gender of main readers. Through the visualized analysis of spatio-temporal data, I suggested releasing media content at eight o’clock in the evening and incorporating the specific elements of XXXXXX into the design of XXXXX. My dedication paid off as I got the highest score in the final assessment and suggestions have been adopted and implemented.'
    },
    {
        id: 'basic4',
        title: 'HKUST Offer',
        content:
            'My interest in pursuing this path can be traced back primarily to my experiences as an undergraduate majoring in XXXXX. Through my early studies, I was fascinated to complete courses on topics public policy and research methods. To apply what I‘ve learned in class to reality, I have participated in various academic research, social practice and internships. From the se experiences, I fully understand the connotation of public policy and its impaction society. These made me more determined to have a career related to XXXXX analysis in the future'
    },
    {
        id: 'basic5',
        title: 'KCL Offer',
        content:
            'In my undergraduate studies, I took full advantage of XXXXX‘s rigorous courses, where I fulfilled the requirement to achieve double degrees in XXXXX. During this process, I gradually developed my own perspective of approaching cultural and creative industries. For some theorists, the beauty of human creations lies in their contributions to aesthetics, and some philosophers like Fredric Jameson even express their concern about how capitalist economy may jeopardize the cultural industry in the postmodern era. Nonetheless, instead of pessimistically regarding capital as the enemy of human creativity, I hold that cultural production can and will flourish with the proper management of capital. Therefore, making a mark in the cultural industry through seamless integration of culture and capital, creativity and productivity has gradually become my unwavering goal. '
    }
];

const descriptionData = [[], Interest, Education, Other, Plan]

// ==============================|| SAMPLE PAGE ||============================== //

const Examples = ({category}) => {
    const intl = useIntl();
    return (
        <MainCard title={intl.formatMessage({id: "Good CVEG"})} spacing={3} style={{ marginBottom: '16px' }}>
            <Grid item xs>
                <Accordion data={descriptionData[category]} />
            </Grid>
        </MainCard>
    )
};

export default Examples;
