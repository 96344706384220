import React, { useState, useEffect } from 'react';
import { Button, Grid, Stack, TextField, Tooltip, IconButton, Typography, Alert, Skeleton } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ExportToWordButton from 'ui-component/cards/ExportToWordButton';
import MainCard from 'ui-component/cards/MainCard';
import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import SelectableCard from 'ui-component/cards/SelectableCard';
import SkeletonCard from 'ui-component/cards/SkeletonCard';
import Examples from './Examples';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { setCredit } from 'store/slices/account';
import { generate, saveChoice } from 'api/generate';
import { LoadingButton } from '@mui/lab';
import { saveChosen, saveOther, setIns, setIn, setOldChosen, setContentID } from 'store/slices/psData';
import { FormattedMessage, useIntl } from 'react-intl';
import { getPS } from 'api/ps';

// ==============================|| SAMPLE PAGE ||============================== //
function Other() {
    const [showExample, setShowExample] = useState(false);
    const [displayOther, setDisplayOther] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initing, setIniting] = useState(true);
    const basicInfo = useSelector(state => state.psData).basicData;
    const generated = useSelector(state => state.psData).psOther;
    const id = useSelector(state => state.psData).id
    const otherID = useSelector(state => state.psData).contentIDs[3];
    const input = useSelector(state => state.psData).ins[3]
    const choice = useSelector(state => state.psData).psChosen[3]
    const [selectedCardOther, setSelectedCardOther] = useState('');
    const [userInputOther, setUserInputOther] = useState('');
    const [optionOther, setOptionOther] = useState([]);

    const [errorOther, setErrorOther] = useState(false);
    const [helperTextOther, setHelperTextOther] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const intl = useIntl();
    
    const [status, setStatus] = useOutletContext();
    const [apiMessageOther, setApiMessageOther] = useState('');

    const handleCardClickOther = (index) => {
        setSelectedCardOther(index);
    };

    const handleButtonClickOther = async () => {
        // Check if input is empty
        if (userInputOther.trim() === '') {
            setStatus({other: 5});
            setErrorOther(true);
            setHelperTextOther(intl.formatMessage({id: "Required"}));
            return;
        }
        if (basicInfo === '') {
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({id: "Please Input Basic Info First"}),
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            navigate(`/ps/basicinfo/${id}`);
        } else {
            setShowExample(false);
            setApiMessageOther('');
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({id: "CV Generating"}),
                    variant: 'alert',
                    alert: {
                        color: 'warning'
                    },
                    close: false
                })
            );
            setStatus({other: 2});
            setLoading(true);
            dispatch(setIn({index: 3, content: userInputOther}))
            generate(otherID, basicInfo, userInputOther, 'other', 3).then( res => {
                setOptionOther(res.data.result);
                setDisplayOther(true);
                dispatch(
                    saveOther(res.data.result)
                )
                dispatch(
                    setCredit(res.data.credit)
                )
                setStatus({other: 3});
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({id: "Generate Success"}),
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
                setApiMessageOther(`Data sent successfully: ${JSON.stringify(res.data.result)}`);
            }).catch( err => {
                if (err.response.data.errorCode == 2) {
                    setApiMessageOther(`${intl.formatMessage({id: "Credit Inadequate"})}`)
                } else {
                    setApiMessageOther(`${intl.formatMessage({id: "Generate Failed"})}: ${err.toString()}`);
                }
                setStatus({other: 5})
                setDisplayOther(false);
            }).finally(() => {
                setLoading(false);
            })
        }
    };
    const handleInputChange = (event) => {
        setStatus({other: 1});
        setUserInputOther(event.target.value);
    };
    const handleOption = () => {
        const otherData = {
            choice: selectedCardOther,
            id: otherID
        };
        setStatus({other: 2});
        setLoading(true);
        saveChoice(otherData).then( res => {
            // console.log('Data sent successfully.', otherData);
            setStatus({other: 4});
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({id: "Choose Success"}),
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            
            dispatch(
                saveChosen([3, selectedCardOther])
            )
            navigate(`/ps/plan/${id}`);
        }).catch( err => {
            // console.error('Error sending data:', err);
            setStatus({other: 6})
            dispatch(
                openSnackbar({
                    message: intl.formatMessage({id: "Choose Failed"}),
                    variant: 'alert',
                    alert: { color: 'error' }
                })
            );
        }).finally(() => {
            setLoading(false);
        })
        
    };

    const handleContentChange = (newContent, index) => {
        setOptionOther(prev => {
            const newArr = prev.slice();
            newArr[index] = newContent;
            return newArr;
        })
        sessionStorage.setItem('optionOther', JSON.stringify(optionOther));
    }

    useEffect(() => {
        getPS(id).then( res => {
            dispatch(saveOther(res.data.other.output))
            dispatch(setIn({index: 3, content: res.data.other.input || ''}))
            dispatch(saveChosen([3, res.data.other.choice]))
            dispatch(setContentID({index: 3, contentID: res.data.other._id}))
        }).catch( err => {

        }).finally(() => setIniting(false));
    }, [])
    // useEffect(() => {
    //     setOptionOther(useSelector(state => state.psData).psOther || []);
    // }, []);
    useEffect(() => {
        // const savedUserInput = sessionStorage.getItem('userInputOther');
        // const savedSelectedCard = sessionStorage.getItem('selectedCardOther');
        // const savedDisplay = sessionStorage.getItem('displayOther');
        // const savedOptions = JSON.parse(sessionStorage.getItem('optionOther'));
        // const savedApiMessage = sessionStorage.getItem('apiMessageOther');
        // if (savedUserInput) setUserInputOther(savedUserInput);
        // if (savedSelectedCard) setSelectedCardOther(parseInt(savedSelectedCard));
        // if (savedDisplay === 'true') setDisplayOther(true);
        // if (savedOptions) setOptionOther(savedOptions) || dispatch(saveOther(savedOptions));
        // if (savedApiMessage) setApiMessageOther(savedApiMessage);
        setSelectedCardOther(choice);
        setUserInputOther(input);
        setOptionOther(generated);
        if (generated.length > 0) setDisplayOther(true);
    }, [generated, input, choice]);

    // useEffect(() => {
    //     sessionStorage.setItem('userInputOther', userInputOther);
    //     sessionStorage.setItem('selectedCardOther', selectedCardOther);
    //     sessionStorage.setItem('displayOther', displayOther);
    //     sessionStorage.setItem('optionOther', JSON.stringify(optionOther));
    //     sessionStorage.setItem('apiMessageOther', apiMessageOther);
    //     if (optionOther !== null && optionOther.length > 0) {
    //         setDisplayOther(true);
    //     }
    // }, [
    //     userInputOther,
    //     selectedCardOther,
    //     displayOther,
    //     optionOther,
    //     apiMessageOther
    // ]);

    return (
        <Grid>
            {initing ? 
            <div>
                <MainCard title={<Skeleton />} style={{ marginBottom: '16px' }}>
                    <Grid container warp="nowarp" spacing={3}>
                        <Grid item xs>
                            <Skeleton />
                            <Skeleton />
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton />
                            <Skeleton />
                        </Grid>
                    </Grid>
                </MainCard>
            </div>
            : 
            <div>
                <MainCard title={intl.formatMessage({id: "Other Background"})} style={{ marginBottom: '16px' }}>
                    <Grid container warp="nowarp" spacing={3}>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                multiline
                                rows={8}
                                placeholder={intl.formatMessage({id: "Other HelperText"})}
                                label={intl.formatMessage({id: "Other Label"})}
                                focused
                                value={userInputOther}
                                onChange={handleInputChange}
                                error={errorOther}
                                helperText={helperTextOther}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" spacing={0}>
                                <Button
                                    variant="text"
                                    startIcon={<AttachmentTwoToneIcon />}
                                    type="button"
                                    onClick={() => setShowExample(!showExample)}
                                >
                                    {showExample === false ? intl.formatMessage({id: 'Good CVEG'}) : intl.formatMessage({id: 'Close'})}
                                </Button>
                                <Stack direction="row" justifyContent="flex-end">
                                        <LoadingButton variant="contained" type="submit" onClick={handleButtonClickOther} loading={loading || status.other === 2}>
                                            {(displayOther === true && optionOther.length > 0)? intl.formatMessage({id: 'Regenerate'}) : intl.formatMessage({id: 'Generate'})}
                                        </LoadingButton>
                                </Stack>
                            </Grid>
                            {/* Display API status and message */}
                            {status.other === 2 && <Typography><FormattedMessage id='Loading' />...</Typography>}
                            {status.other === 5 && userInputOther.trim() !== '' && (
                                <Typography color="error">{intl.formatMessage({id: "Generate Error"}) + apiMessageOther}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </MainCard>
                {showExample && <Examples category={3} />}
                {(displayOther === true && optionOther.length > 0) && (
                    <MainCard
                        title={intl.formatMessage({id: "Other Para"})}
                        secondary={
                            <Tooltip title={intl.formatMessage({id: "Export"})} placement="top-start">
                                <IconButton>
                                    <ExportToWordButton content={optionOther} fileName={"other"} />
                                </IconButton>
                            </Tooltip>
                        }
                    >
                        <Grid item xs={12} style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                        {intl.formatMessage({id: "Please Choose"}) + intl.formatMessage({id: "Other Para"})}
                        </Grid>
                        {(status.other === 2 && optionOther.length === 0)? (
                            <SkeletonCard />
                        ) : (
                            <Grid>
                                {
                                    optionOther.map((op, i) => {
                                        return <SelectableCard
                                            index={i}
                                            content={op}
                                            isSelected={selectedCardOther === i}
                                            onSelect={handleCardClickOther}
                                            onContentChange={handleContentChange}
                                        />
                                    })
                                }
                            </Grid>
                        )}
                        {optionOther.length > 0 && (
                            <div style={{ textAlign: 'right' }}>
                                <LoadingButton variant="contained" onClick={handleOption} loading={loading || status.other === 2}>
                                    <FormattedMessage id="Submit" />
                                </LoadingButton>
                            </div>
                        )}
                    </MainCard>
                )}
            </div>
            }
        </Grid>
    );
}
export default Other;
