// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconDeviceAnalytics, IconLayoutGrid, IconCirclePlus } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconDeviceAnalytics,
    IconLayoutGrid,
    IconCirclePlus
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const manage = {
    id: 'manage',
    title: <FormattedMessage id="Manage" />,
    icon: icons.IconHelp,
    type: 'group',
    children: [
        {
            id: 'manage',
            title: <FormattedMessage id="All PS" />,
            type: 'item',
            url: '/ps/manage',
            icon: icons.IconLayoutGrid,
            breadcrumbs: false,
            status: 0
        },
    ]
};

export default manage;
