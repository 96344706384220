import PSCard from 'ui-component/cards/PSCard'
import { Card, Grid, Button, Divider } from '@mui/material'
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import {getAllPS, getPS, createNewPS} from 'api/ps'
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import { FileDownload, InsertDriveFileOutlined, FileUploadOutlined } from '@mui/icons-material';
import { openSnackbar } from 'store/slices/snackbar';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { setContentIDs, setID, setTitle } from 'store/slices/psData';
import { LoadingButton } from '@mui/lab';
const Manage = () => {
    const [cursor, setCursor] = useState("");
    const [cardElevation, setCardElevation] = useState(8);
    const [psList, setPsList] = useState([]);
    const [askCreate, setAskCreate] = useState(false);
    const [newPSTitle, setNewPSTitle] = useState("");
    const [loading, setLoading] = useState(true);
    const [createLoading, setCreateLoading] = useState(false);
    const [useUpload, setUseUpload] = useState(0);
    const user = useSelector(state => state.account).user;
    const contentIDs = useSelector(state => state.psData).contentIDs;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const intl = useIntl();
    const initPS = () => {
        setLoading(true)
        getAllPS(user).then(res => {
            if (res.data.essays instanceof Array) {
                setPsList(res.data.essays)
            }
        }).catch( err => {
            dispatch(openSnackbar({
                message: intl.formatMessage({id: "Get PS Failed"}) + ", " + err.toString(),
                variant: 'alert',
                alert: { color: 'error' },
            }))
        }).finally(() => {
            setLoading(false);
        })
    }
    const createNew = async () => {
        // setCardElevation(2)
        setCreateLoading(true);
        // get user email from redux
        
        createNewPS(user, newPSTitle).then( res => {
            if (res.data.id) {
                dispatch(openSnackbar({
                    message: intl.formatMessage({id: "Create PS Success"}),
                    variant: 'alert',
                    alert: { color: 'success' },
                }))
                dispatch(setID(res.data.id))
                dispatch(setTitle(newPSTitle))
                dispatch(setContentIDs([res.data.basicInfoId, ...res.data.paragraphIds, res.data.previewId]))
                console.log(contentIDs)
                setAskCreate(false);
                if (useUpload) {
                    navigate(`/ps/addtemplate`)
                } else {
                    navigate(`/ps/basicinfo/${res.data.id}`)
                }
            } else {
                dispatch(openSnackbar({
                    message: intl.formatMessage({id: "Create PS Failed"}),
                    variant: 'alert',
                    alert: { color: 'error' },
                }))
            }
        }).catch(err => {
            dispatch(openSnackbar({
                message: intl.formatMessage({id: "Create PS Failed"}) + ", " + err.toString(),
                variant: 'alert',
                alert: { color: 'error' },
            }))
        }).finally(() => {
            setCreateLoading(false);
        })
    }

    const handleClose = () => {
        setAskCreate(false);
      }

    const PSList = () => {
        return psList.map((ps) => {
            return (
                <Grid item xs={4} sm={3} md={4} lg={3}>
                    <PSCard psID={ps.id} psTitle={ps.title || ''} psPreview={ps.input} refresh={initPS} setLoading={setLoading} />
                </Grid>
            )
        })
    }

    useEffect(() => {
        initPS();
    }, [user])
    return (
        <Grid container columns={{ xs: 4, sm: 6, md: 12, lg: 12 }}>
            <Grid item xs={4} sm={3} md={4} lg={3}>
                    <Card sx={{ minWidth: 180, minHeight: 240 }} style={{margin: '8px', cursor: cursor}} elevation={cardElevation} onClick={() => setAskCreate(true)} onMouseOver={() => setCursor("pointer")} onMouseLeave={() => setCursor("")} onMouseUp={() => setCardElevation(8)} onMouseDown={() => setCardElevation(2)}>
                        <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                            <Typography sx={{ fontSize: 40 }} style={{textAlign: 'center', verticalAlign: 'center', margin: '10px', marginTop: '40px'}} >
                                +
                            </Typography>
                            <Typography sx={{ fontSize: 25 }} style={{textAlign: 'center', marginTop: '40px' }} >
                                <FormattedMessage id="New PS" />
                            </Typography>
                        </CardContent>
                    </Card>
            </Grid>
            {loading ? <Grid item xs={4} sm={3} md={4} lg={3}>
                <Card sx={{ minWidth: 180, minHeight: 240 }} style={{margin: '8px'}} elevation={8}>
                    <CardContent style={{minHeight: 185, maxHeight: 185, overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                            <Skeleton />
                        </Typography>
                        <div variant="body2">
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </div>
                        
                    </CardContent>
                    <div>
                        <Divider varient="middle" />
                        <div style={{textAlign: 'center', display: 'flex', justifyContent: "space-around", padding: '5px'}}>
                            <IconButton size="medium" disabled={true}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton size="medium" disabled={true}>
                                <FileDownload />
                            </IconButton>
                        </div>
                    </div>
                </Card>
            </Grid>: <PSList />}
            {/* {psList.map((ps) => {
                return (
                    <Grid item xs={4} sm={3} md={4} lg={3}>
                        <PSCard psID={ps.id} psTitle={ps.title || ''} psPreview={ps.input} refresh={initPS} setLoading={setLoading} />
                    </Grid>
                )
            })} */}
            <Dialog open={askCreate} onClose={handleClose} >
                <DialogTitle><FormattedMessage id="New PS" /></DialogTitle>
                <DialogContent>
                <DialogContentText>
                    <FormattedMessage id="Choose PS Type" />
                </DialogContentText>
                <div style={{display: 'flex', justifyContent: 'space-around', minWidth: '350px', marginTop: '15px', marginBottom: '15px'}}>
                    <Card onClick={() => {setUseUpload(false)}}>
                        <div style={useUpload ? {padding: '1px'}: {padding: '1px', boxShadow: '0 0 0 3px #322BDA inset'}}>
                            <div style={{margin: '5px', minWidth: '120px', textAlign: 'center'}}>
                                <InsertDriveFileOutlined style={{fontSize: '96px'}} />
                                <p style={{margin: 0}}><FormattedMessage id="Standard" /></p>
                            </div>
                        </div>
                    </Card>
                    <Card onClick={() => {setUseUpload(true)}}>
                        <div style={!useUpload ? {padding: '1px'}: {padding: '1px', boxShadow: '0 0 0 3px #322BDA inset'}}>
                            <div style={{margin: '5px', minWidth: '120px', textAlign: 'center'}}>
                                <FileUploadOutlined style={{fontSize: '96px'}} />
                                <p style={{margin: 0}}><FormattedMessage id="Upload PS" /></p>
                            </div>
                        </div>
                    </Card>
                </div>
                
                <DialogContentText>
                    <FormattedMessage id="Give New PS Name" />
                </DialogContentText>
                <TextField
                    value={newPSTitle}
                    onChange={(e) => setNewPSTitle(e.target.value)}
                    margin="dense"
                    id="ps-name"
                    type="text"
                    fullWidth
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}><FormattedMessage id="Cancel" /></Button>
                <LoadingButton onClick={createNew} disabled={newPSTitle.length == 0} loading={createLoading} ><FormattedMessage id="Yes" /></LoadingButton>
                </DialogActions>
            </Dialog>
        </Grid>
        
    )
}

export default Manage