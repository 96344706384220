import React, { useState, useEffect } from 'react';
import { Button, Grid, Stack, TextField, Tooltip, IconButton, Typography, Alert, Skeleton } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import ExportToWordButton from 'ui-component/cards/ExportToWordButton';
import MainCard from 'ui-component/cards/MainCard';
import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import SelectableCard from 'ui-component/cards/SelectableCard';
import SkeletonCard from 'ui-component/cards/SkeletonCard';
import Examples from './Examples';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { useSelector } from 'react-redux';
import { setCredit } from 'store/slices/account';
import { generate, saveChoice } from 'api/generate'
import { LoadingButton } from '@mui/lab';
import { saveChosen, saveInterest, setIns, setIn, setOldChosen, setContentID } from 'store/slices/psData';
import { FormattedMessage, useIntl } from 'react-intl';
import { getPS } from 'api/ps';

// ==============================|| SAMPLE PAGE ||============================== //

function Interest() {
    const [showExample, setShowExample] = useState(false);
    const [displayInterest, setDisplayInterest] = useState(false);
    const basicInfo = useSelector(state => state.psData).basicData;
    const generated = useSelector(state => state.psData).psInterest;
    const id = useSelector(state => state.psData).id
    const interestID = useSelector(state => state.psData).contentIDs[1];
    const input = useSelector(state => state.psData).ins[1]
    const choice = useSelector(state => state.psData).psChosen[1]
    const [selectedCardInterest, setSelectedCardInterest] = useState(0);
    const [userInputInterest, setUserInputInterest] = useState('');
    const [optionInterest, setOptionInterest] = useState([]);
    const [errorInterest, setErrorInterest] = useState(false);
    const [helperTextInterest, setHelperTextInterest] = useState('');
    const [loading, setLoading] = useState(false);
    const [initing, setIniting] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const intl = useIntl();

    const [status, setStatus] = useOutletContext();
    const handleCardClickInterest = (index) => {
        setSelectedCardInterest(index);
    };
    const [apiMessageInterest, setApiMessageInterest] = useState('');

    const handleButtonClickInterest = async () => {
        // Check if input is empty
        if (userInputInterest.trim() === '') {
            setStatus({interest: 5})
            setErrorInterest(true);
            setHelperTextInterest(intl.formatMessage({id: "Required"}));
            return;
        }
        if (basicInfo === '') {
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({id: "Please Input Basic Info First"}),
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            navigate(`/ps/basicinfo/${id}`);
        } else {
            setShowExample(false);
            setApiMessageInterest('');
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({id: "CV Generating"}),
                    variant: 'alert',
                    alert: {
                        color: 'warning'
                    },
                    close: false
                })
            );
            setStatus({interest: 2})
            setLoading(true)
            dispatch(setIn({index: 1, content: userInputInterest}))
            generate(interestID, basicInfo, userInputInterest, 'Interest', 1).then( res => {
                setDisplayInterest(true);
                // setUserInputInterest(userInputInterest);
                setOptionInterest(res.data.result);
                dispatch(
                    saveInterest(res.data.result)
                );
                setStatus({interest: 3})
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({id: "Generate Success"}),
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
                dispatch(
                    setCredit(res.data.credit)
                )
                setApiMessageInterest(`Data sent successfully: ${JSON.stringify(res.data)}`);
            }).catch ( err => {
                if (err.response.data.errorCode == 2) {
                    setApiMessageInterest(`${intl.formatMessage({id: "Credit Inadequate"})}`)
                } else {
                    setApiMessageInterest(`${intl.formatMessage({id: "Generate Failed"})}: ${err.toString()}`);
                }
                setStatus({interest: 5})
                setDisplayInterest(false);
            }).finally(() => {
                setLoading(false);
            })
        }
    };
    const handleInputChange = (event) => {
        setStatus({interest: 1})
        setUserInputInterest(event.target.value);
    };
    const handleOption = () => {
        const interestData = {
            choice: selectedCardInterest,
            id: interestID
        };
        setLoading(true)
        setStatus({interest: 2})
        saveChoice(interestData).then( res => {
            setStatus({interest: 4})
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({id: "Choose Success"}),
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(
                saveChosen([1, selectedCardInterest])
            )
            // console.log('Data sent successfully.', interestData);
            navigate(`/ps/education/${id}`);
        }).catch ( err => {
            // console.error('Error sending data:', err);
            setStatus({interest: 6})
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({id: "Choose Failed"}),
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
        }).finally(() => {
            setLoading(false);
        })
            
    };

    const handleContentChange = (newContent, index) => {
        setOptionInterest(prev => {
            const newArr = prev.slice();
            newArr[index] = newContent;
            return newArr;
        })
        // sync to backend with output edit
        sessionStorage.setItem('optionInterest', JSON.stringify(optionInterest));
    }

    useEffect(() => {
        getPS(id).then( res => {
            dispatch(saveInterest(res.data.interest.output))
            dispatch(setIn({index: 1, content: res.data.interest.input || ''}))
            dispatch(saveChosen([1, res.data.interest.choice]))
            dispatch(setContentID({index: 1, contentID: res.data.interest._id}))
        }).catch( err => {

        }).finally(() => setIniting(false));
    }, [])

    useEffect(() => {
        // const savedUserInput = sessionStorage.getItem('userInputInterest');
        // const savedSelectedCard = sessionStorage.getItem('selectedCardInterest');
        // const savedDisplay = sessionStorage.getItem('displayInterest');
        // const savedOptions = JSON.parse(sessionStorage.getItem('optionInterest'));
        // const savedApiMessage = sessionStorage.getItem('apiMessageInterest');
        // if (savedUserInput) setUserInputInterest(savedUserInput);
        // if (savedSelectedCard) setSelectedCardInterest(parseInt(savedSelectedCard));
        // if (savedDisplay === 'true') setDisplayInterest(true);
        // if (savedOptions) setOptionInterest(savedOptions) || dispatch(saveInterest(savedOptions));
        // if (savedApiMessage) setApiMessageInterest(savedApiMessage);
        console.log(choice, input, generated)
        setSelectedCardInterest(choice);
        setUserInputInterest(input || '');
        setOptionInterest(generated);
        if (generated.length > 0) setDisplayInterest(true);
    }, [generated, input, choice]);

    // useEffect(() => {
    //     sessionStorage.setItem('userInputInterest', userInputInterest);
    //     sessionStorage.setItem('selectedCardInterest', selectedCardInterest);
    //     sessionStorage.setItem('displayInterest', displayInterest);
    //     sessionStorage.setItem('optionInterest', JSON.stringify(optionInterest));
    //     sessionStorage.setItem('apiMessageInterest', apiMessageInterest);
    //     if (optionInterest !== null && optionInterest.length > 0) {
    //         setDisplayInterest(true);
    //     }
    // }, [
    //     userInputInterest,
    //     selectedCardInterest,
    //     displayInterest,
    //     optionInterest,
    //     apiMessageInterest
    // ]);


    return (
        <Grid>
            {initing ? 
            <div>
                <MainCard title={<Skeleton />} style={{ marginBottom: '16px' }}>
                    <Grid container warp="nowarp" spacing={3}>
                        <Grid item xs>
                            <Skeleton />
                            <Skeleton />
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton />
                            <Skeleton />
                        </Grid>
                    </Grid>
                </MainCard>
            </div>
            : 
            <div>
                <MainCard title={intl.formatMessage({id: "Interest"})} style={{ marginBottom: '16px' }}>
                    <Grid container warp="nowarp" spacing={3}>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                multiline
                                rows={8}
                                placeholder={intl.formatMessage({id: "Interest HelperText"})}
                                label={intl.formatMessage({id: "Interest Label"})}
                                focused
                                value={userInputInterest}
                                onChange={handleInputChange}
                                error={errorInterest}
                                helperText={helperTextInterest}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" spacing={0}>
                                <Button
                                    variant="text"
                                    startIcon={<AttachmentTwoToneIcon />}
                                    type="button"
                                    onClick={() => setShowExample(!showExample)}
                                >
                                    {showExample === false ? intl.formatMessage({id: 'Good CVEG'}) : intl.formatMessage({id: 'Close'})}
                                </Button>
                                <Stack direction="row" justifyContent="flex-end">
                                    <LoadingButton variant="contained" type="submit" onClick={handleButtonClickInterest} loading={loading || status.interest === 2}>
                                        {(displayInterest === true && optionInterest.length > 0) ? intl.formatMessage({id: 'Regenerate'}) : intl.formatMessage({id: 'Generate'})}
                                    </LoadingButton>
                                </Stack>
                            </Grid>
                            {/* Display API status and message */}
                            {status.interest === 2 && <Typography><FormattedMessage id='Loading' />...</Typography>}
                            {status.interest === 5 && userInputInterest.trim() !== '' && (
                                <Typography color="error">{intl.formatMessage({id: "Generate Error"}) + apiMessageInterest}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </MainCard>
                {showExample && <Examples category={1} />}
                {(displayInterest === true && optionInterest.length > 0) && (
                    <MainCard
                        title={intl.formatMessage({id: "Interest Para"})}
                        secondary={
                            <Tooltip title={intl.formatMessage({id: "Export"})} placement="top-start">
                                <IconButton>
                                    <ExportToWordButton content={optionInterest} fileName={"interest"} />
                                </IconButton>
                            </Tooltip>
                        }
                    >
                        <Grid item xs={12} style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                            {intl.formatMessage({id: "Please Choose"}) + intl.formatMessage({id: "Interest Para"})}
                        </Grid>
                        {(status.interest === 2 && optionInterest.length === 0) ? (
                            <SkeletonCard />
                        ) : (
                            <Grid>
                                {optionInterest.map((op, i) => {
                                    return (
                                        <SelectableCard
                                            key={i}
                                            index={i}
                                            content={op}
                                            isSelected={selectedCardInterest === i}
                                            onSelect={handleCardClickInterest}
                                            onContentChange={handleContentChange}
                                        />
                                    )
                                })}
                            </Grid>
                        )}
                        {optionInterest.length > 0 && (
                            <div style={{ textAlign: 'right' }}>
                                <LoadingButton variant="contained" onClick={handleOption} loading={loading || status.interest === 2}>
                                    <FormattedMessage id="Submit" />
                                </LoadingButton>
                            </div>
                        )}
                    </MainCard>
                )}
            </div>
            }
        </Grid>
    );
}
export default Interest;
