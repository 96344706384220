// material-ui
import React, { useState, useEffect } from 'react';
import { Grid, Typography, Tooltip, IconButton, Button, Stack, CircularProgress, Skeleton } from '@mui/material';
import { gridSpacing } from 'store/constant';
import Box from '@mui/material/Box';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { LoadingButton } from '@mui/lab';
import ExportToWordButton from 'ui-component/cards/ExportToWordButton';
import { useSelector } from 'react-redux';
import { generateFinal, sseConnect } from 'api/generate';
import { useOutletContext } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { getPS } from 'api/ps';
import { savePreview, setIn, setContentID } from 'store/slices/psData';
import { setCredit } from 'store/slices/account';

// ==============================|| SAMPLE PAGE ||============================== //
function Preview() {
    let chosen = useSelector(state => state.psData).psChosen;
    const [status, setStatus] = useOutletContext();
    const previewID = useSelector(state => state.psData).contentIDs[5]
    // console.log(chosen)
    // const InterestSelectOption = chosen[1] >= 0 ? useSelector(state => state.psData).psInterest[chosen[1]] :'You haven\'t chosen the Interest Paragragh yet.' ;
    // const EducationSelectOption = chosen[2] >= 0 ? useSelector(state => state.psData).psEducation[chosen[2]] : 'You haven\'t chosen the Education Paragragh yet.' ;
    // const OtherSelectOption = chosen[3] >= 0 ? useSelector(state => state.psData).psOther[chosen[3]] : 'You haven\'t chosen the Other Paragragh yet.' ;
    // const PlanSelectOption = chosen[4] >= 0 ? useSelector(state => state.psData).psPlan[chosen[4]] : 'You haven\'t chosen the Plan Paragragh yet.' ;
    const basicInfo = useSelector(state => state.psData).basicData;
    const id = useSelector(state => state.psData).id
    const [paragraphs, setParagraphs] = useState([])
    const dispatch = useDispatch();
    const [apiMessagePreview, setApiMessagePreview] = useState('');
    const [finalOutput, setFinalOutput] = useState('')
    const [loading, setLoading] = useState(false);
    const [isFinished4Para, setIsFinished4Para] = useState(true);
    const [initing, setIniting] = useState(true);
    
    let sse = {};

    const intl = useIntl();
    const generateUniqueEventId = () => {
        return "preview_" + Math.random().toString(16).slice(2);
    };
    const setUpSse = async () => {
        sse = sseConnect();
        const id = generateUniqueEventId();
        sse.addEventListener(id, (e) => {
            const data = JSON.parse(e.data);
            setFinalOutput((f) => f + data.content);
        });
        sse.onopen = () => {
            console.log('sse opened');
        };
        sse.onerror = (e) => {
            console.log('sse error', e);
        };
        return id;
    }
    const handleButtonClickPreview = async () => {
        setUpSse().then((eventId) => {
            setApiMessagePreview('');
            setFinalOutput('');
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({id: "CV Refactoring Please Wait"}),
                    variant: 'alert',
                    alert: {
                        color: 'warning'
                    },
                    close: false
                })
            );
            setLoading(true);
            setStatus({preview: 2});
            generateFinal(previewID, eventId, basicInfo, paragraphs, 'preview', 0).then(res => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({id: "Refactor Success"}),
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
                setStatus({preview: 4});
                dispatch(
                    setCredit(res.data.credit)
                )
                setFinalOutput(res.data.result);
            }).catch( err => {
                if (err.response.data.errorCode == 2) {
                    setApiMessagePreview(`${intl.formatMessage({id: "Credit Inadequate"})}`)
                } else {
                    setApiMessagePreview(`${intl.formatMessage({id: "Generate Failed"})}: ${err.toString()}`);
                }
                setStatus({preview: 5})
            }).finally(() => {
                setLoading(false);
                sse.close();
            })
        });
    };
    useEffect(() => {
        getPS(id).then( res => {
            let items = [res.data.interest, res.data.education, res.data.other, res.data.plan]
            dispatch(savePreview(res.data.preview.output))
            dispatch(setIn({index: 5, content: res.data.preview.input}))
            dispatch(setContentID({index: 5, contentID: res.data.preview._id}))
            setFinalOutput(res.data.preview.output)
            setParagraphs(items.map((item, i) => {
                if (item.output.length > 0 && item.choice > -1) {
                    return item.output[item.choice]
                } else {
                    setIsFinished4Para(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: intl.formatMessage({id: "Please Refactor After Generating 4 Para"}),
                            variant: 'alert',
                            alert: {
                                color: 'warning'
                            },
                            close: true
                        })
                    );
                    return `You haven\'t chosen Paragragh ${i + 1} yet.`
                }
            }))
        }).catch( err => {

        }).finally(() => setIniting(false));
    
    }, [])
    
    return (
        <Grid container spacing={gridSpacing}>
            
            <Grid item xs={12} md={6}>
                <MainCard
                    title={intl.formatMessage({id: "Total Preview"})}
                    xs={6}
                    secondary={
                        <Tooltip title={intl.formatMessage({id: "Export"})} placement="top-start">
                            <IconButton>
                                <ExportToWordButton content={paragraphs} fileName={"preview"} />
                            </IconButton>
                        </Tooltip>
                    }
                >
                    {initing ? 
                        <Grid container warp="nowarp" spacing={3}>
                            <Grid item xs>
                                <Skeleton />
                                <Skeleton />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Grid>
                        </Grid>
                    :
                        <div>
                            {paragraphs.map((paragraph, index) => (
                                <Box mb={3} key={index}>
                                    <Typography variant="subtitle1" component="p">
                                        {paragraph}
                                    </Typography>
                                </Box>
                            ))}
                            {status.preview === 2 && <Typography>Loading...</Typography>}
                            {status.preview === 5 && (
                                <Typography color="error">{intl.formatMessage({id: "Generate Error"}) + apiMessagePreview} </Typography>
                            )}
                            {chosen.slice(1).indexOf(-1) === -1 ? (
                                <Stack direction="row" justifyContent="flex-end">
                                    <AnimateButton>
                                        <LoadingButton variant="contained" type="submit" onClick={handleButtonClickPreview} loading={loading || status.preview === 2} disabled={!isFinished4Para}>
                                            <FormattedMessage id="CV Refactoring" />
                                        </LoadingButton>
                                    </AnimateButton>
                                </Stack>
                            ) : (
                                <Stack direction="row" justifyContent="flex-end">
                                    <AnimateButton>
                                        <Tooltip title={intl.formatMessage({id: "Please Refactor After Generating 4 Para"})}>
                                            <span>
                                                <Button variant="contained" disabled>
                                                    <FormattedMessage id="CV Refactoring" />
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </AnimateButton>
                                </Stack>
                            )}
                        </div>
                    }
                </MainCard>
            </Grid>
            
            <Grid item xs={12} md={6}>
                <MainCard
                    title={intl.formatMessage({id: "CV Refactoring"})}
                    xs={6}
                    secondary={
                        <Tooltip title={intl.formatMessage({id: "Export"})} placement="top-start">
                            <IconButton>
                                <ExportToWordButton content={[finalOutput]} fileName={"final"} />
                            </IconButton>
                        </Tooltip>
                    }
                >
                    {initing ? 
                        <Grid container warp="nowarp" spacing={3}>
                            <Grid item xs>
                                <Skeleton />
                                <Skeleton />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Grid>
                        </Grid>
                    :
                        <Box mb={3}>
                            {status.preview === 2 ? (
                                <Grid container spacing={3} justifyContent="center">
                                    <Grid item>
                                        <CircularProgress aria-label="progress" />
                                    </Grid>
                                </Grid>
                            ) : (
                                <Typography variant="subtitle1" component="p" style={{ whiteSpace: 'pre-line' }}>
                                    {finalOutput}
                                </Typography>
                            )}
                        </Box>
                }
                </MainCard>
            </Grid>
            
        </Grid>
    );
}

export default Preview;
