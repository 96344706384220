import { useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, Container, CssBaseline, Toolbar, useMediaQuery, Skeleton, Grid, Typography, Card } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
// project imports
import Header from './Header';
import Sidebar from './Sidebar';

import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import { drawerWidth } from 'store/constant';
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { getPS } from 'api/ps';
import { saveBasicInfo, saveEducation, saveInterest, saveOther, savePlan, savePreview, setID, setTitle, setContentIDs, setIns, setOldChosen } from 'store/slices/psData';
import { setCredit } from 'store/slices/account';

import { getCredit } from 'api/credit';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, layout }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter + 200
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: layout === LAYOUT_CONST.VERTICAL_LAYOUT ? -(drawerWidth - 72) : '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginTop: 88
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px',
            marginTop: 88
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter + 200
        }),
        marginLeft: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? '20px' : 0,
        marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.up('md')]: {
            marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            marginTop: 88
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            marginTop: 88
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //
// eslint-disable-next-line
const MainLayout = () => {
    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const { drawerType, container, layout } = useConfig();
    const [loading, setLoading] = useState(true);

    const [open, setOpen] = useState(drawerType === LAYOUT_CONST.DEFAULT_DRAWER && drawerOpen);
    const splitPath = window.location.pathname.split('/')
    const currentID = useSelector(state => state.psData).id || splitPath.length === 4 ? splitPath[3]: '';
    const currentPSTitle = useSelector(state => state.psData).title
    const [ fillingStatus, setFillingStatus ] = useState({
        basicinfo: 0,
        interest: 0,
        education: 0,
        other: 0,
        plan: 0,
        preview: 0
    });

    const setStatus = (o) => {
        setFillingStatus( prev => {
            let newStatus = Object.assign({}, prev);
            for(let k in o) {
                newStatus[k] = o[k];
            }
            return newStatus;
        })
        // TODO: update status to backend
        sessionStorage.setItem("status", JSON.stringify(fillingStatus))
    }

    useEffect(() => {
        getCredit().then( res => {
            dispatch(
                setCredit(res.data.credit)
            )
        })
    }, [])

    useEffect(() => {
        if (currentID) {
            getPS(currentID).then( res => {
                if (res.data.id) {
                    dispatch(setID(res.data.id))
                    dispatch(setTitle(res.data.title))
                    dispatch(saveBasicInfo(res.data.basicInfo))
                    dispatch(saveInterest(res.data.interest.output))
                    dispatch(saveEducation(res.data.education.output))
                    dispatch(savePlan(res.data.plan.output))
                    dispatch(saveOther(res.data.other.output))
                    dispatch(savePreview(res.data.preview.output))
                    dispatch(setContentIDs([res.data.basicInfo._id, res.data.interest._id, res.data.education._id, res.data.other._id, res.data.plan._id, res.data.preview._id]))
                    dispatch(setIns([null, res.data.interest.input, res.data.education.input, res.data.other.input, res.data.plan.input, res.data.preview.input]))
                    dispatch(setOldChosen([-1, res.data.interest.choice, res.data.education.choice, res.data.other.choice, res.data.plan.choice]))
                }
            }).catch( err => {
                console.log(err)
            }).finally( () => {
                setLoading(false);
            })
        }
        
    }, []);

    useEffect(() => {
        if (!currentID) setLoading(false);
    }, [currentID])

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            setOpen(true);
            dispatch(openDrawer(true));
        } else {
            setOpen(false);
            dispatch(openDrawer(false));
        }
    }, [drawerType]);

    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer(!open));
    };

    useEffect(() => {
        if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
            dispatch(openDrawer(true));
        }
    }, []);

    useEffect(() => {
        if (matchDownMd) {
            setOpen(true);
            dispatch(openDrawer(true));
        }
    }, [matchDownMd]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
    }, [drawerOpen]);

    const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

    const AppBarHeader = () => (
            <Toolbar sx={{ p: condition ? '10px' : '16px' }}>
                <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            </Toolbar>
        );
    
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                position="fixed"
                elevation={0}
                sx={{
                    bgcolor: 'background.default',
                    transition: drawerOpen ? theme.transitions.create('width') : 'none'
                }}
            >
                {<AppBarHeader />}
            </AppBar>

            {/* drawer */}
            {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && <Sidebar key={1} open={open} status={fillingStatus} handleDrawerToggle={handleDrawerToggle} />}

            {/* main content */}
            <Main theme={theme} open={drawerOpen} layout={layout}>
                <Container maxWidth={container ? 'lg' : false}>
                    {/* breadcrumb */}
                    {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
                    {loading ? 
                        <>
                            {currentID ? <MainCard><Typography><Skeleton /></Typography></MainCard>: ''}
                            <MainCard title={<Skeleton />} style={{ marginBottom: '16px' }}>
                                <Grid container warp="nowarp" spacing={3}>
                                    <Grid item xs>
                                        <Skeleton />
                                        <Skeleton />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Skeleton />
                                        <Skeleton />
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </>
                        : 
                        <>
                            {currentID ? <MainCard style={{marginBottom: '10px'}}>
                                <Typography sx={{ fontSize: 18 }}>{currentPSTitle}</Typography>
                            </MainCard>: ''}
                            <Outlet context={[fillingStatus, setStatus]} />
                        </>
                    }

                </Container>
            </Main>
        </Box>
    );
};

export default MainLayout;
