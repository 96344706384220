import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useState, useReducer } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'store';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography
} from '@mui/material';
// third party
import * as Yup from 'yup';
import { Formik, useFormik } from 'formik';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import useAuth from 'hooks/useAuth';
import useScriptRef from 'hooks/useScriptRef';

import { LoadingButton } from '@mui/lab';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import * as auth from 'api/auth';

import { openSnackbar } from 'store/slices/snackbar';
import { login, logout } from 'store/slices/account';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { getCredit } from 'api/credit';
import { setCredit } from 'store/slices/account';

const initialValues = {
    email: '',
    password: '',
    submit: null
}
// ===============================|| JWT LOGIN ||=============================== //

const JWTLogin = ({ loginProp, type, ...others }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const intl = useIntl();

    const [checked, setChecked] = React.useState(true);

    const [cntdInterval, setCntdInterval] = useState(0);
    const [second, setSecond] = useState(120);
    const [gettingOTP, setGettingOTP] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [countingDown, setCountingDown] = useState(false);

    const [showPassword, setShowPassword] = React.useState(false);

    const dispatch = useDispatch();
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const isValidEmail = (email) => {
        let pattern = /^([0-9a-zA-Z_\.\-\])+\@([0-9a-zA-Z_\.\-\])+\.([a-zA-Z]+)$/
        return pattern.test(email)
    }

    const fmik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Must be a valid email').max(255).required('Required'),
            password: Yup.string().max(255).required('Required')
        }),
        onSubmit: async (values, {}) => {
            setSubmitting(true);
            auth.login(type, values.email, values.password).then( res => {
                setSubmitting(false);
                dispatch(
                    openSnackbar({
                        message: intl.formatMessage({id: "Login Success"}),
                        variant: 'alert',
                        alert: { color: 'success' },
                    })
                );
                dispatch(login({
                    isLoggedIn: true,
                    user: res.data.email
                }));
                localStorage.setItem('serviceToken', res.data.token);
                localStorage.setItem('user', res.data.user);
                getCredit().then(res => {
                    dispatch(
                        setCredit(res.data.credit)
                    )
                })
                setTimeout(() => {
                    navigate('/ps/manage', {replace: true})
                })
            }).catch(err => {
                setSubmitting(false);
                dispatch(
                    openSnackbar({
                        message: `${intl.formatMessage({id: "Login Failed"})}, ${err.response.data.message}`,
                        variant: 'alert',
                        alert: { color: 'error' },
                    })
                );
            })
        }
    })


    const getOTP = (email) => {
        if (!isValidEmail(email)) return;
        setGettingOTP(true);
        auth.getRegOTP(email, 1).then( res => {
            if (res.status === 200) {
                setGettingOTP(false);
                dispatch(
                    openSnackbar({
                        message: intl.formatMessage({id: "OTP Success"}),
                        variant: 'alert',
                        alert: { color: 'success' },
                    })
                )
                setCountingDown(true);
            } else {
                setGettingOTP(false);
                dispatch(
                    openSnackbar({
                        message: intl.formatMessage({id: "OTP Failed"}),
                        variant: 'alert',
                        alert: { color: 'error' },
                    })
                )
            }
        }).catch( err => {
            dispatch(
                openSnackbar({
                    message: `${intl.formatMessage({id: "OTP Failed"})}, ${err.response.data.message}`,
                    variant: 'alert',
                    alert: { color: 'error' },
                })
            )
            setGettingOTP(false);
        })
    }

    useEffect(() => {
        if (countingDown) {
            setCntdInterval(setInterval(() => {
                setSecond(sec => --sec);
            }, 1000)) 
        }
    }, [countingDown])

    useEffect(() => {
        if (second <= 0) {
            setGettingOTP(false);
            setCountingDown(false);
            clearInterval(cntdInterval);
            setSecond(120)
        }
    }, [second])

    useEffect(() => {
        fmik.handleReset();
    }, [type])

    return (
        <form noValidate onSubmit={fmik.handleSubmit} {...others}>
            {type === 0 ? (
                <>
                    <FormControl fullWidth error={Boolean(fmik.touched.email && fmik.errors.email)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-email-login"><FormattedMessage id="Email" /></InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-email-login"
                            type="email"
                            value={fmik.values.email}
                            name="email"
                            onBlur={fmik.handleBlur}
                            onChange={fmik.handleChange}
                            inputProps={{}}
                        />
                        {fmik.touched.email && fmik.errors.email && (
                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                {fmik.errors.email}
                            </FormHelperText>
                        )}
                    </FormControl>

                    <FormControl fullWidth error={Boolean(fmik.touched.password && fmik.errors.password)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-password-login"><FormattedMessage id="Password" /></InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password-login"
                            type={showPassword ? 'text' : 'password'}
                            value={fmik.values.password}
                            name="password"
                            onBlur={fmik.handleBlur}
                            onChange={fmik.handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        size="large"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            inputProps={{}}
                        />
                        {fmik.touched.password && fmik.errors.password && (
                            <FormHelperText error id="standard-weight-helper-text-password-login">
                                {fmik.errors.password}
                            </FormHelperText>
                        )}
                    </FormControl>
                </>
            ): (
                <>
                    <FormControl fullWidth error={Boolean(fmik.touched.email && fmik.errors.email)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-email-login"><FormattedMessage id="Email" /></InputLabel>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="email"
                                value={fmik.values.email}
                                name="email"
                                onBlur={fmik.handleBlur}
                                onChange={fmik.handleChange}
                                inputProps={{}}
                                fullWidth
                            />
                                <LoadingButton onClick={() => {getOTP(fmik.values.email)}} loading={gettingOTP} disabled={countingDown}>{countingDown ? second.toString() + 's': <div><div>{intl.formatMessage({id: "Send"})}</div><div>{intl.formatMessage({id: "OTP"})}</div></div>}</LoadingButton>
                        </div>
                        {fmik.touched.email && fmik.errors.email && (
                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                {fmik.errors.email}
                            </FormHelperText>
                        )}
                    </FormControl>

                    <FormControl fullWidth error={Boolean(fmik.touched.password && fmik.errors.password)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-password-login"><FormattedMessage id="OTP" /></InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password-login"
                            type={'text'}
                            value={fmik.values.password}
                            name="password"
                            onBlur={fmik.handleBlur}
                            onChange={fmik.handleChange}
                            inputProps={{}}
                        />
                        {fmik.touched.password && fmik.errors.password && (
                            <FormHelperText error id="standard-weight-helper-text-password-login">
                                {fmik.errors.password}
                            </FormHelperText>
                        )}
                    </FormControl>
                </>
            )}

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={(event) => setChecked(event.target.checked)}
                                name="checked"
                                color="primary"
                            />
                        }
                        label={intl.formatMessage({id: "Keep Me Logged In"})}
                    />
                </Grid>
            </Grid>

            {fmik.errors.submit && (
                <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{fmik.errors.submit}</FormHelperText>
                </Box>
            )}
            <Box sx={{ mt: 2 }}>
                <LoadingButton color="secondary" loading={submitting} fullWidth size="large" type="submit" variant="contained">
                <FormattedMessage id="Sign In" />
                </LoadingButton>
            </Box>
        </form>
    );
};

JWTLogin.propTypes = {
    loginProp: PropTypes.number
};

export default JWTLogin;
