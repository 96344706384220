import React, { useState, useEffect } from 'react';
import { Button, Grid, Stack, TextField, Tooltip, IconButton, Typography, Alert, Skeleton } from '@mui/material';

import { useNavigate, useOutletContext } from 'react-router-dom';
import ExportToWordButton from 'ui-component/cards/ExportToWordButton';
import MainCard from 'ui-component/cards/MainCard';
import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import SelectableCard from 'ui-component/cards/SelectableCard';
import SkeletonCard from 'ui-component/cards/SkeletonCard';
import Examples from './Examples';
import { useSelector } from 'react-redux';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { generate, saveChoice } from 'api/generate'
import { LoadingButton } from '@mui/lab';
import { saveChosen, saveEducation, setIns, setIn, setOldChosen, setContentID } from 'store/slices/psData';
import { setCredit } from 'store/slices/account';
import { FormattedMessage, useIntl } from 'react-intl';
import { getPS } from 'api/ps';

// ==============================|| SAMPLE PAGE ||============================== //
function Education() {
    const [showExample, setShowExample] = useState(false);
    const [displayEducation, setDisplayEducation] = useState(false);
    const basicInfo = useSelector(state => state.psData).basicData;
    const generated = useSelector(state => state.psData).psEducation;
    const id = useSelector(state => state.psData).id
    const educationID = useSelector(state => state.psData).contentIDs[2];
    const input = useSelector(state => state.psData).ins[2]
    const choice = useSelector(state => state.psData).psChosen[2]
    const [selectedCardEducation, setSelectedCardEducation] = useState(0);
    const [userInputEducation, setUserInputEducation] = useState('');
    const [optionEducation, setOptionEducation] = useState([]);
    const [errorEducation, setErrorEducation] = useState(false);
    const [helperTextEducation, setHelperTextEducation] = useState('');
    const [loading, setLoading] = useState(false);
    const [initing, setIniting] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const intl = useIntl();
    const handleCardClickEducation = (index) => {
        setSelectedCardEducation(index);
    };

    const [status, setStatus] = useOutletContext();
    const [apiMessageEducation, setApiMessageEducation] = useState('');

    const handleButtonClickEducation = async () => {
        // Check if input is empty
        if (userInputEducation.trim() === '') {
            setStatus({education: 5})
            setErrorEducation(true);
            setHelperTextEducation(intl.formatMessage({id: "Required"}));
            return;
        }
        if (basicInfo === '') {
            dispatch(
                openSnackbar({
                    message: intl.formatMessage({id: "Please Input Basic Info First"}),
                    variant: 'alert',
                    alert: { color: 'error' }
                })
            );
            navigate(`/ps/basicinfo/${id}`);
        } else {
            setShowExample(false);
            setApiMessageEducation('');
            dispatch(
                openSnackbar({
                    message: intl.formatMessage({id: "CV Generating"}),
                    variant: 'alert',
                    alert: { color: 'warning' },
                })
            );
            setStatus({education: 2})
            setLoading(true)
            dispatch(setIn({index: 2, content: userInputEducation}))
            generate(educationID, basicInfo, userInputEducation, 'Education', 2).then( res => {
                setDisplayEducation(true);
                setOptionEducation(res.data.result);
                dispatch(
                    saveEducation(res.data.result)
                )
                dispatch(
                    setCredit(res.data.credit)
                )
                setStatus({education: 3});
                dispatch(
                    openSnackbar({
                        message: intl.formatMessage({id: "Generate Success"}),
                        variant: 'alert',
                        alert: { color: 'success' }
                    })
                );
                setApiMessageEducation(`Data sent successfully: ${JSON.stringify(res.data)}`)
            }).catch ((err) => {
                if (err.response.data.errorCode == 2) {
                    setApiMessageEducation(`${intl.formatMessage({id: "Credit Inadequate"})}`)
                } else {
                    setApiMessageEducation(`${intl.formatMessage({id: "Generate Failed"})}: ${err.toString()}`);
                }
                setStatus({education: 5})
                setDisplayEducation(false);
            }).finally(() => {
                setLoading(false);
            })
        }
    };
    const handleInputChange = (event) => {
        setStatus({education: 1})
        setUserInputEducation(event.target.value);
    };
    const handleOption = () => {
        const educationData = {
            choice: selectedCardEducation,
            id: educationID
        };
        setLoading(true)
        setStatus({education: 2})
        saveChoice(educationData).then( res => {
            setStatus({education: 4});
            dispatch(
                openSnackbar({
                    message: intl.formatMessage({id: "Choose Success"}),
                    variant: 'alert',
                    alert: { color: 'success' }
                })
            );
            
            dispatch(
                saveChosen([2, selectedCardEducation])
            )
            navigate(`/ps/other/${id}`);
        }).catch ( err => {
            // console.error('Error sending data:', err);
            setStatus({education: 6})
            dispatch(
                openSnackbar({
                    message: intl.formatMessage({id: "Choose Failed"}),
                    variant: 'alert',
                    alert: { color: 'error' }
                })
            );
        }).finally(() => {
            setLoading(false);
        })
        
    };

    const saveInput = () => {
        // save user input to backend
        setSaveLoading(true);
        setInterval(() => setSaveLoading(false), 2000);
    }
    const handleContentChange = (newContent, index) => {
        setOptionEducation(prev => {
            const newArr = prev.slice();
            newArr[index] = newContent;
            return newArr;
        })
        // sync to backend with output edit
        sessionStorage.setItem('optionEducation', JSON.stringify(optionEducation));
    }

    useEffect(() => {
        getPS(id).then( res => {
            dispatch(saveEducation(res.data.education.output))
            dispatch(setIn({index: 2, content: res.data.education.input || ''}))
            dispatch(saveChosen([2, res.data.education.choice]))
            dispatch(setContentID({index: 2, contentID: res.data.education._id}))
        }).catch( err => {

        }).finally(() => setIniting(false));
    }, [])
    
    useEffect(() => {
        // const savedUserInput = sessionStorage.getItem('userInputEducation');
        // const savedSelectedCard = sessionStorage.getItem('selectedCardEducation');
        // const savedDisplay = sessionStorage.getItem('displayEducation');
        // const savedOptions = JSON.parse(sessionStorage.getItem('optionEducation'));
        // const savedApiMessage = sessionStorage.getItem('apiMessageEducation');
        // if (savedOptions) setOptionEducation(savedOptions) || dispatch(saveEducation(savedOptions));
        // if (savedUserInput) setUserInputEducation(savedUserInput);
        // if (savedSelectedCard) setSelectedCardEducation(parseInt(savedSelectedCard));
        // if (savedDisplay === 'true') setDisplayEducation(true);
        // if (savedApiMessage) setApiMessageEducation(savedApiMessage);
        setSelectedCardEducation(choice);
        setUserInputEducation(input);
        setOptionEducation(generated);
        if (generated.length > 0) setDisplayEducation(true);
    }, [generated, input, choice]);

    // useEffect(() => {
    //     sessionStorage.setItem('userInputEducation', userInputEducation);
    //     sessionStorage.setItem('selectedCardEducation', selectedCardEducation);
    //     sessionStorage.setItem('displayEducation', displayEducation);
    //     sessionStorage.setItem('optionEducation', JSON.stringify(optionEducation));
    //     sessionStorage.setItem('apiMessageEducation', apiMessageEducation);
    //     if (optionEducation !== null && optionEducation.length > 0) {
    //         setDisplayEducation(true);
    //     }
    // }, [
    //     userInputEducation,
    //     selectedCardEducation,
    //     displayEducation,
    //     optionEducation,
    //     apiMessageEducation
    // ]);

    return (
        <Grid>
            {initing ? 
            <div>
                <MainCard title={<Skeleton />} style={{ marginBottom: '16px' }}>
                    <Grid container warp="nowarp" spacing={3}>
                        <Grid item xs>
                            <Skeleton />
                            <Skeleton />
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton />
                            <Skeleton />
                        </Grid>
                    </Grid>
                </MainCard>
            </div>
            :
            <div>
                <MainCard title={intl.formatMessage({id: "Education Background"})} style={{ marginBottom: '16px' }}>
                    <Grid container warp="nowarp" spacing={3}>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                multiline
                                rows={8}
                                placeholder={intl.formatMessage({id: "Education HelperText"})}
                                label={intl.formatMessage({id: "Education Label"})}
                                focused
                                value={userInputEducation}
                                onChange={handleInputChange}
                                error={errorEducation}
                                helperText={helperTextEducation}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" spacing={0}>
                                <Button
                                    variant="text"
                                    startIcon={<AttachmentTwoToneIcon />}
                                    type="button"
                                    onClick={() => setShowExample(!showExample)}
                                >
                                    {showExample === false ? intl.formatMessage({id: 'Good CVEG'}) : intl.formatMessage({id: 'Close'})}
                                </Button>
                                <Stack direction="row" justifyContent="space-around">
                                    <LoadingButton variant="contained" type="submit" onClick={handleButtonClickEducation} loading={loading || status.education === 2}>
                                        {(displayEducation === true && optionEducation.length > 0) ? intl.formatMessage({id: 'Regenerate'}) : intl.formatMessage({id: 'Generate'})}
                                    </LoadingButton>
                                    {/* <LoadingButton variant="contained" type="submit" onClick={saveInput} loading={saveLoading} style={{marginLeft: '3px'}}>
                                        保存并停留此页
                                    </LoadingButton> */}
                                </Stack>
                            </Grid>
                            {/* Display API status and message */}
                            {status.education === 2 && <Typography><FormattedMessage id='Loading' />...</Typography>}
                            {status.education === 5 && userInputEducation.trim() !== '' && (
                                <Typography color="error">{intl.formatMessage({id: "Generate Error"}) + apiMessageEducation} </Typography>
                            )}
                        </Grid>
                    </Grid>
                </MainCard>
                {showExample && <Examples category={2} />}
                {(displayEducation === true && optionEducation.length > 0) && (
                    <MainCard
                        title={intl.formatMessage({id: "Education Para"})}
                        secondary={
                            <Tooltip title={intl.formatMessage({id: "Export"})} placement="top-start">
                                <IconButton>
                                    <ExportToWordButton content={optionEducation} fileName={"education"} />
                                </IconButton>
                            </Tooltip>
                        }
                    >
                        <Grid item xs={12} style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                            {intl.formatMessage({id: "Please Choose"}) + intl.formatMessage({id: "Education Para"})}
                        </Grid>
                        {(status.education === 2 && optionEducation.length === 0) ? (
                            <SkeletonCard />
                        ) : (
                            <Grid>
                                {optionEducation.map((op, i) => {
                                    return <SelectableCard
                                        key={i}
                                        index={i}
                                        content={op}
                                        isSelected={selectedCardEducation === i}
                                        onSelect={handleCardClickEducation}
                                        onContentChange={handleContentChange}
                                    /> 
                                })}
                            </Grid>
                        )}
                        {optionEducation.length > 0 && (
                            <div style={{ textAlign: 'right' }}>
                                <LoadingButton variant="contained" onClick={handleOption} loading={loading || status.education === 2} >
                                    <FormattedMessage id="Submit" />
                                </LoadingButton>
                            </div>
                        )}
                    </MainCard>
                )}
            </div>
            }
        </Grid>
    );
}
export default Education;
