import React, { useEffect, useState } from 'react';
import { useDispatch } from 'store';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import Markdown from 'react-markdown'

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import useAuth from 'hooks/useAuth';
import useScriptRef from 'hooks/useScriptRef';
import { strengthColor, strengthIndicatorNumFunc } from 'utils/password-strength';
import { openSnackbar } from 'store/slices/snackbar';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';

import { getRegOTP, register } from 'api/auth';
import { zhTerms, enTerms } from 'utils/locales/terms'
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import useConfig from 'hooks/useConfig';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const JWTRegister = ({ ...others }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const dispatch = useDispatch();
    const intl = useIntl();
    const { locale } = useConfig();
    const [cntdInterval, setCntdInterval] = useState(0);

    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = useState(true);

    const [second, setSecond] = useState(120);
    const [gettingOTP, setGettingOTP] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [countingDown, setCountingDown] = useState(false);

    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();

    const [openTerms, setOpenTerms] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicatorNumFunc(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    const handleClose = () => {
        setOpenTerms(false);
    }

    const isValidEmail = (email) => {
        let pattern = /^([0-9a-zA-Z_\.\-\])+\@([0-9a-zA-Z_\.\-\])+\.([a-zA-Z]+)$/
        return pattern.test(email)
    }

    useEffect(() => {
        if (countingDown) {
            setCntdInterval(setInterval(() => {
                setSecond(sec => --sec);
            }, 1000)) 
        }
    }, [countingDown])

    useEffect(() => {
        if (second <= 0) {
            setGettingOTP(false);
            setCountingDown(false);
            clearInterval(cntdInterval);
            setSecond(120)
        }
    }, [second])

    const getOTP = (email) => {
        if (!isValidEmail(email)) return;
        setGettingOTP(true);
        getRegOTP(email, 0).then( res => {
            if (res.status === 200) {
                setGettingOTP(false);
                dispatch(
                    openSnackbar({
                        message: intl.formatMessage({id: "OTP Success"}),
                        variant: 'alert',
                        alert: { color: 'success' },
                    })
                )
                setCountingDown(true);
            } else {
                setGettingOTP(false);
                dispatch(
                    openSnackbar({
                        message: intl.formatMessage({id: "OTP Failed"}),
                        variant: 'alert',
                        alert: { color: 'error' },
                    })
                )
            }
        }).catch( err => {
            console.log(err.response)
            dispatch(
                openSnackbar({
                    message: `${intl.formatMessage({id: "OTP Failed"})}, ${err.response.data.message}`,
                    variant: 'alert',
                    alert: { color: 'error' },
                })
            )
            setGettingOTP(false);
        })
    }

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1"><FormattedMessage id="Sign Up With Email" /></Typography>
                    </Box>
                </Grid>
            </Grid>

            <Formik
                initialValues={{
                    email: '',
                    otp: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Required'),
                    password: Yup.string().max(255).required('Required'),
                    otp: Yup.string().required('Required')
                })}
                onSubmit={async (values, {}) => {
                    setSubmitting(true);
                    register(values.email, values.otp, values.password).then( res => {
                        dispatch(
                            openSnackbar({
                                message: intl.formatMessage({id: "Reg Success"}),
                                variant: 'alert',
                                alert: { color: 'success' },
                            })
                        );
                        setSubmitting(false);
                        setTimeout(() => {
                            navigate('/login', { replace: true });
                        }, 500);
                    }).catch( err => {
                        console.log(err)
                        dispatch(
                            openSnackbar({
                                message: `${intl.formatMessage({id: "Reg Failed"})}, ${err.response.message}`,
                                variant: 'alert',
                                alert: { color: 'error' },
                            })
                        );
                        setSubmitting(false);
                    })
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-register"><FormattedMessage id="Email" /></InputLabel>
                            <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                <OutlinedInput
                                    id="outlined-adornment-email-register"
                                    type="email"
                                    value={values.email}
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    inputProps={{}}
                                    style={{width: '100%'}}
                                />
                                <LoadingButton onClick={() => {getOTP(values.email)}} loading={gettingOTP} disabled={countingDown}>{countingDown ? second.toString() + 's': <div><div>{intl.formatMessage({id: "Send"})}</div><div>{intl.formatMessage({id: "OTP"})}</div></div>}</LoadingButton>
                            </div>
                            {touched.email && errors.email && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.otp && errors.otp)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-otp-register"><FormattedMessage id="OTP" /></InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-otp-register"
                                value={values.otp}
                                name="otp"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                            />
                            {touched.otp && errors.otp && (
                                <FormHelperText error id="standard-weight-helper-text-otp-register">
                                    {errors.otp}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-register"><FormattedMessage id="Password" /></InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-register"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                    changePassword(e.target.value);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                inputProps={{}}
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-register">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>

                        {strength !== 0 && (
                            <FormControl fullWidth>
                                <Box sx={{ mb: 2 }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <Box
                                                style={{ backgroundColor: level?.color }}
                                                sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" fontSize="0.75rem">
                                                {level?.label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </FormControl>
                        )}

                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={(event) => setChecked(event.target.checked)}
                                            name="checked"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Typography variant="subtitle1">
                                            <FormattedMessage id="Agree" /> &nbsp;
                                            <Typography variant="subtitle1" component={Link} to="#" onClick={() => setOpenTerms(true)}>
                                            <FormattedMessage id="Terms Conditions" />
                                            </Typography>
                                        </Typography>
                                    }
                                />
                                <Dialog
                                    open={openTerms}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        <FormattedMessage id="Terms Conditions" />
                                    </DialogTitle>
                                    <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {locale === 'zh' ? <Markdown>{zhTerms}</Markdown> : <Markdown>{enTerms}</Markdown>}
                                    </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                    <Button onClick={handleClose} autoFocus>
                                        <FormattedMessage id="Close" />
                                    </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                        </Grid>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <LoadingButton
                                disableElevation
                                loading={submitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="secondary"
                            >
                                <FormattedMessage id="Sign Up" />
                            </LoadingButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default JWTRegister;
