import React, { useState } from 'react';
import { Button } from '@mui/material';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Box, Typography } from '@mui/material';

import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';

const FileUploader = ({ uploadPath, onUpload }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [previousFiles, setPreviousFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const intl = useIntl();

  const handleFileChange = (event) => {
    const files = event.target.files;
    const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    const selectedFilesArray = Array.from(files);

    const invalidFiles = selectedFilesArray.filter(
      (file) => !allowedTypes.includes(file.type)
    );

    if (invalidFiles.length > 0) {
      setErrorMessage('Invalid file type. Please select PDF or DOC files.');
    } else {
      if (previousFiles.length > 0 && selectedFilesArray.length == 0) setSelectedFiles(previousFiles);
      else setSelectedFiles(selectedFilesArray);
      setErrorMessage('');
    }
  };

  const handleFileDelete = () => {
    setSelectedFiles([]);
    setErrorMessage('');
    setPreviousFiles([]);
  };

  const handleUpload = async () => {
    // Handle file upload logic here
    // You can use a library like axios to send files to the server
    // Example: axios.post(uploadPath, selectedFiles);
    if (onUpload) {
      setLoading(true);
      await onUpload(selectedFiles[0])
      setLoading(false);
    }
    console.log('Uploading files:', selectedFiles[0]);
  };

  const handleSelectFiles = () => {
    document.getElementById('file-input').click();
  };

  const handleReupload = () => {
    // Keep the previously selected files if the user cancels file selection
    setPreviousFiles(selectedFiles);
    // Clear selected files to allow re-selection
    // setSelectedFiles([]);
    // Trigger file selection dialog
    document.getElementById('file-input').click();
  };

  return (
    <Box border={0} borderRadius={4} p={2} m={2} bgcolor="grey.100">
      <input
        style={{ display: 'none' }}
        id="file-input"
        type="file"
        accept=".pdf,.doc,.docx"
        onChange={handleFileChange}
      />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {selectedFiles.length === 0 ? (
          <>
            <p style={{marginTop: '16px', fontSize: '16px'}}>{intl.formatMessage({id: 'Upload PS'})}</p>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon />}
              style={{ marginTop: '8px' }}
              onClick={handleSelectFiles}
            >
              {intl.formatMessage({id: 'Select Files'})}
            </Button>
          </>
        ) : (
          <>
            <div style={{ marginTop: '16px' }}>
              {selectedFiles.map((file, index) => (
                <Typography key={index} variant="body1" style={{ marginBottom: '8px' }}>
                  {file.name}
                </Typography>
              ))}
            </div>
            <div style={{ marginTop: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleReupload}
              >
                {intl.formatMessage({id: 'Reupload'})}
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                style={{ marginLeft: '8px' }}
                onClick={handleUpload}
                loading={loading}
              >
                {intl.formatMessage({id: 'Parse PS'})}
              </LoadingButton>
            </div>
          </>
        )}
        {errorMessage && (
          <Typography variant="body2" style={{ color: 'red', marginTop: '8px' }}>
            {errorMessage}
          </Typography>
        )}
      </div>
    </Box>
  );
};

export default FileUploader;