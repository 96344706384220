import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userID: '',
  title: '',
  id: '',
  contentIDs: [0, 0, 0, 0, 0, 0],
  ins: [null, '', '', '', '', ''],
  basicData: {
    currentSchool: '',
    currentMajor: '',
    currentGPA: '',
    applySchool: '',
    applyMajor: ''
  },
  // outs
  psEducation: [],
  psInterest: [],
  psPlan: [],
  psOther: [],
  psPreview: '',
  psChosen: JSON.parse(sessionStorage.getItem("psChosen") || '""') || [-1, -1, -1, -1, -1]
}

const psData = createSlice({
  name: 'ps',
  initialState,
  reducers: {
    saveBasicInfo: (state, action) => {
      const basicInfo = action.payload;
      state.basicData = basicInfo;
      sessionStorage.setItem("basicData", JSON.stringify(state.basicData));
    },

    saveEducation: (state, action) => {
      const education = action.payload;
      state.psEducation = education;
      sessionStorage.setItem("psEducation", JSON.stringify(state.psEducation));
    },

    saveInterest: (state, action) => {
      const interest = action.payload;
      state.psInterest = interest;
      sessionStorage.setItem("psInterest", JSON.stringify(state.psInterest));
    },

    savePlan: (state, action) => {
      const plan = action.payload;
      state.psPlan = plan;
      sessionStorage.setItem("psPlan", JSON.stringify(state.psPlan));
    },

    saveOther: (state, action) => {
      const other = action.payload;
      state.psOther = other;
      sessionStorage.setItem("psOther", JSON.stringify(state.psOther));
    },

    savePreview: (state, action) => {
      const preview = action.payload;
      state.psPreview = preview
    },

    saveChosen: (state, action) => {
      const [idx, num] = action.payload;
      const chosen = state.psChosen.slice();
      chosen[idx] = num;
      state.psChosen = chosen;
      sessionStorage.setItem("psChosen", JSON.stringify(state.psChosen));
    },

    setOldChosen: (state, action) => {
      const chosen = action.payload;
      if (chosen instanceof Array)
      state.psChosen = chosen;
    },

    setID: (state, action) => {
      const id = action.payload;
      if (id) {
        state.id = id;
      }
    },

    setTitle: (state, action) => {
      const title = action.payload;
      if (title) {
        state.title = title;
      }
    },

    setContentIDs: (state, action) => {
      const contentIDs = action.payload;
      if (contentIDs.length > 0) {
        state.contentIDs = contentIDs;
      }
    },

    setContentID: (state, action) => {
      const o = action.payload;
      if (o.contentID && o.index > -1) {
        state.contentIDs[o.index] = o.contentID
      }
    },

    setIns: (state, action) => {
      const ins = action.payload;
      if (ins.length > 0) {
        state.ins = ins;
      }
    },

    setIn: (state, action) => {
      const o = action.payload;

      if (o.index) {
        state.ins[o.index] = o.content;
      }
    }
  }
})

export default psData.reducer;

export const { saveBasicInfo, saveEducation, saveInterest, saveOther, savePlan, savePreview, saveChosen, setOldChosen, setID, setTitle, setContentID, setContentIDs, setIns, setIn } = psData.actions;
