// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconDeviceAnalytics
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const guidance = {
    id: 'guidance',
    title: <FormattedMessage id="Guidance" />,
    icon: icons.IconHelp,
    type: 'group',
    children: [
        {
            id: 'video',
            title: <FormattedMessage id="Guidance Video" />,
            type: 'item',
            url: '/guidance/video',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false,
            status: 0
        }
    ]
};

export default guidance;
