import PropTypes from 'prop-types';
import { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { checkLogin } from 'api/auth';
import { login, logout } from 'store/slices/account';
// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const loginStatus = useSelector(state => state.account);
    const { isLoggedIn } = loginStatus;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isLoggedIn) {
            checkLogin().then( res => {
                if (res.data.user) {
                    console.log(res.data.user.email)
                    dispatch(
                        login({
                            isLoggedIn: true,
                            user: res.data.user.email
                        })
                    );
                    
                } else {
                    dispatch(
                        logout({
                            isLoggedIn: false,
                            user: null
                        })
                    );
                    navigate('login', {replace: true});
                }
            }).catch(err => {
                navigate('login', {replace: true})
            })
        }
    }, [isLoggedIn, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
