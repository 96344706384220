import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { FileDownload } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage, useIntl } from 'react-intl';
import { deletePS } from 'api/ps';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openSnackbar } from 'store/slices/snackbar';
import { setID, setTitle } from 'store/slices/psData';
export default function PSCard({psID, psTitle, psPreview, refresh, setLoading}) {
  const [cursor, setCursor] = useState("");
  const [cardElevation, setCardElevation] = useState(8);
  const [askDelete, setAskDelete] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const delPS = () => {
    setAskDelete(false);
    deletePS(psID).then( res => {
      if (res.data.message) {
        dispatch(openSnackbar({
          message: intl.formatMessage({id: "Delete PS Success"}),
          variant: 'alert',
          alert: { color: 'success' },
        }))
        refresh();
      } else {
        dispatch(openSnackbar({
          message: intl.formatMessage({id: "Delete PS Failed"}),
          variant: 'alert',
          alert: { color: 'error' },
        }))
      }
    }).catch( err => {
      dispatch(openSnackbar({
        message: intl.formatMessage({id: "Delete PS Failed"}) + ", " + err.toString(),
        variant: 'alert',
        alert: { color: 'error' },
      }))
    })
  }

  const handleClose = () => {
    setAskDelete(false);
  }

  const handleClick = () => {
    dispatch(setID(psID));
    dispatch(setTitle(psTitle));
    navigate(`/ps/basicinfo/${psID}`)
  }
  
  return (
    <Card sx={{ minWidth: 180, minHeight: 240, maxHeight: 240 }} style={{margin: '8px', cursor: cursor }} elevation={cardElevation} >
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative'}}>
        <CardContent style={{minHeight: 185, maxHeight: 185, overflow: 'hidden', textOverflow: 'ellipsis'}} onMouseOver={() => setCursor("pointer")} onMouseLeave={() => setCursor("")} onMouseUp={() => setCardElevation(8)} onMouseDown={() => setCardElevation(2)} onClick={handleClick}>
          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
            {psTitle}
          </Typography>
          <div variant="body2">
            {psPreview}
          </div>
        </CardContent>
        <div>
          <Divider varient="middle" />
          <div style={{textAlign: 'center', display: 'flex', justifyContent: "space-around", padding: '5px'}}>
            <IconButton size="medium" onClick={() => setAskDelete(true)}>
              <DeleteIcon />
            </IconButton>
            <IconButton size="medium">
              <FileDownload />
            </IconButton>
          </div>
        </div>
        <Dialog
          open={askDelete}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ minWidth: '300px'}}
        >
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage id="Ask Delete" />{" " + psTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormattedMessage id="Ask Delete Content" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button onClick={delPS} >
              <FormattedMessage id="Yes" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Card>
  );
}