// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconTools, IconWindmill, IconClipboardList, IconChartInfographic, IconLayoutGridAdd, IconCheck, IconExclamationCircle } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconTools,
    IconWindmill,
    IconClipboardList,
    IconChartInfographic,
    IconLayoutGridAdd,
    IconCheck,
    IconExclamationCircle
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const ps = {
    id: 'ps',
    title: <FormattedMessage id="Personal Statement" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'basicinfo',
            title: <FormattedMessage id="Basic Information" />,
            type: 'item',
            url: '/ps/basicinfo',
            icon: icons.IconBrandChrome,
            status: 0
        },
        {
            id: 'interest',
            title: <FormattedMessage id="Interest" />,
            type: 'item',
            url: '/ps/interest',
            icon: icons.IconWindmill,
            status: 0
        },
        {
            id: 'education',
            title: <FormattedMessage id="Education Background" />,
            type: 'item',
            url: '/ps/education',
            icon: icons.IconTools,
            status: 0
        },
        {
            id: 'other',
            title: <FormattedMessage id="Other Background" />,
            type: 'item',
            url: '/ps/other',
            icon: icons.IconChartInfographic,
            status: 0
        },
        {
            id: 'plan',
            title: <FormattedMessage id="Future Plan" />,
            type: 'item',
            url: '/ps/plan',
            icon: icons.IconLayoutGridAdd,
            status: 0
        },
        {
            id: 'preview',
            title: <FormattedMessage id="Preview" />,
            type: 'item',
            url: '/ps/preview',
            icon: icons.IconClipboardList,
            status: 0
        }
    ]
};

export default ps;
