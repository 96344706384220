import axiosInstance from './config'

const submitBasicInfo = (basicInfo, basicInfoId, user, title) => {
  return axiosInstance.post(`/userInfo/save`, {...basicInfo, id: basicInfoId, email: user, title: title});
}

const getBasicInfo = () => {

}

export default submitBasicInfo