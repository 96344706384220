import React, { useState, useEffect } from 'react';
import { Button, Grid, Stack, TextField, Tooltip, IconButton, Typography, Alert, Skeleton } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import ExportToWordButton from 'ui-component/cards/ExportToWordButton';
import MainCard from 'ui-component/cards/MainCard';
import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import SelectableCard from 'ui-component/cards/SelectableCard';
import SkeletonCard from 'ui-component/cards/SkeletonCard';
import Examples from './Examples';
import { useSelector } from 'react-redux';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { setCredit } from 'store/slices/account';
import { generate, saveChoice } from 'api/generate';
import { LoadingButton } from '@mui/lab';
import { saveChosen, savePlan, setIns, setIn, setOldChosen, setContentID } from 'store/slices/psData';
import { FormattedMessage, useIntl } from 'react-intl';
import { getPS } from 'api/ps';

// ==============================|| SAMPLE PAGE ||============================== //
function Plan() {
    const [showExample, setShowExample] = useState(false);
    const [displayPlan, setDisplayPlan] = useState(false);
    const basicInfo = useSelector(state => state.psData).basicData;
    const generated = useSelector(state => state.psData).psPlan;
    const id = useSelector(state => state.psData).id
    const planID = useSelector(state => state.psData).contentIDs[4];
    const input = useSelector(state => state.psData).ins[4]
    const choice = useSelector(state => state.psData).psChosen[4]
    const [selectedCardPlan, setSelectedCardPlan] = useState('');
    const [userInputPlan, setUserInputPlan] = useState('');
    const [optionPlan, setOptionPlan] = useState([]);
    const [errorPlan, setErrorPlan] = useState(false);
    const [helperTextPlan, setHelperTextPlan] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [status, setStatus] = useOutletContext();
    const [loading, setLoading] = useState(false);
    const [initing, setIniting] = useState(true);
    const [apiMessagePlan, setApiMessagePlan] = useState('');

    const intl = useIntl();

    const handleCardClickPlan = (index) => {
        setSelectedCardPlan(index);
    };

    const handleButtonClickPlan = async () => {
        // Check if input is empty
        if (userInputPlan.trim() === '') {
            setStatus({plan: 5});
            setErrorPlan(true);
            setHelperTextPlan(intl.formatMessage({id: "Required"}));
            return;
        }
        if (basicInfo === '') {
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({id: "Please Input Basic Info First"}),
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            navigate(`/ps/basicinfo/${id}`);
        } else {
            setShowExample(false);
            setApiMessagePlan('');
            
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({id: "CV Generating"}),
                    variant: 'alert',
                    alert: {
                        color: 'warning'
                    },
                    close: false
                })
            );
            setStatus({plan: 2});
            setLoading(true);
            dispatch(setIn({index: 4, content: userInputPlan}))
            generate(planID, basicInfo, userInputPlan, 'other', 4).then( res => {
                setOptionPlan(res.data.result);
                dispatch(
                    savePlan(res.data.result)
                )
                dispatch(
                    setCredit(res.data.credit)
                )
                setStatus({plan: 3});
                setDisplayPlan(true);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({id: "Generate Success"}),
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: false
                    })
                );
                setApiMessagePlan(`Data sent successfully: ${JSON.stringify(res.data)}`);
            }).catch( err => {
                if (err.response.data.errorCode == 2) {
                    setApiMessagePlan(`${intl.formatMessage({id: "Credit Inadequate"})}`)
                } else {
                    setApiMessagePlan(`${intl.formatMessage({id: "Generate Failed"})}: ${err.toString()}`);
                }
                setStatus({plan: 5})
                setDisplayPlan(false);
            }).finally(() => {
                setLoading(false);
            })
               
            
        }
    };
    const handleInputChange = (event) => {
        setStatus({plan: 1});
        setUserInputPlan(event.target.value);
    };
    const handleOption = () => {
        const planData = {
            choice: selectedCardPlan,
            id: planID
        };
        setStatus({plan: 2});
        setLoading(true);
        saveChoice(planData).then( res => {
            setStatus({plan: 4});
            // console.log('Data sent successfully.', planData);
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({id: "Choose Success"}),
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            
            dispatch(
                saveChosen([4, selectedCardPlan])
            )
            navigate(`/ps/preview/${id}`);
        }).catch( err => {
            setStatus({plan: 6});
            // console.error('Error sending data:', err);
            dispatch(
                openSnackbar({
                    message: intl.formatMessage({id: "Choose Failed"}),
                    variant: 'alert',
                    alert: { color: 'error' }
                })
            );
        }).finally(() => {
            setLoading(false);
        })
    };


    const handleContentChange = (newContent, index) => {
        setOptionPlan(prev => {
            const newArr = prev.slice();
            newArr[index] = newContent;
            return newArr;
        })
        sessionStorage.setItem('optionPlan', JSON.stringify(optionPlan));
    }

    useEffect(() => {
        getPS(id).then( res => {
            dispatch(savePlan(res.data.plan.output))
            dispatch(setIn({index: 4, content: res.data.plan.input || ''}))
            dispatch(saveChosen([4, res.data.plan.choice]))
            dispatch(setContentID({index: 4, contentID: res.data.plan._id}))
        }).catch( err => {

        }).finally(() => setIniting(false));
    }, [])
    // useEffect(() => {
    //     setOptionnPlan(useSelector(state => state.psData).psPlan || []);
    // }, []);
    useEffect(() => {
        // const savedUserInput = sessionStorage.getItem('userInputPlan');
        // const savedSelectedCard = sessionStorage.getItem('selectedCardPlan');
        // const savedDisplay = sessionStorage.getItem('displayPlan');
        // const savedOptions = JSON.parse(sessionStorage.getItem('optionPlan'));
        // const savedApiMessage = sessionStorage.getItem('apiMessagePlan');
        // if (savedSelectedCard) setSelectedCardPlan(parseInt(savedSelectedCard));
        // if (savedUserInput) setUserInputPlan(savedUserInput);
        // if (savedDisplay === 'true') setDisplayPlan(true);
        // if (savedOptions) setOptionPlan(savedOptions) || dispatch(savePlan(savedOptions));
        // if (savedApiMessage) setApiMessagePlan(savedApiMessage);
        setSelectedCardPlan(choice);
        setUserInputPlan(input);
        setOptionPlan(generated);
        if (generated.length > 0) setDisplayPlan(true);
    }, [generated, input, choice]);

    // useEffect(() => {
    //     sessionStorage.setItem('userInputPlan', userInputPlan);
    //     sessionStorage.setItem('selectedCardPlan', selectedCardPlan);
    //     sessionStorage.setItem('displayPlan', displayPlan);
    //     sessionStorage.setItem('optionPlan', JSON.stringify(optionPlan));
    //     sessionStorage.setItem('apiMessagePlan', apiMessagePlan);
    //     if (optionPlan !== null && optionPlan.length > 0) {
    //         setDisplayPlan(true);
    //     }
    // }, [
    //     userInputPlan,
    //     selectedCardPlan,
    //     displayPlan,
    //     optionPlan,
    //     apiMessagePlan
    // ]);

    return (
        <Grid>
            {initing? 
            <div>
                <MainCard title={<Skeleton />} style={{ marginBottom: '16px' }}>
                    <Grid container warp="nowarp" spacing={3}>
                        <Grid item xs>
                            <Skeleton />
                            <Skeleton />
                        </Grid>
                        <Grid item xs={12}>
                            <Skeleton />
                            <Skeleton />
                        </Grid>
                    </Grid>
                </MainCard>
            </div>
            : 
            <div>
                <MainCard title={intl.formatMessage({id: "Future Plan"})} style={{ marginBottom: '16px' }}>
                    <Grid container warp="nowarp" spacing={3}>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                multiline
                                rows={8}
                                placeholder={intl.formatMessage({id: "Future HelperText"})}
                                label={intl.formatMessage({id: "Future Label"})}
                                focused
                                value={userInputPlan}
                                onChange={handleInputChange}
                                error={errorPlan}
                                helperText={helperTextPlan}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" spacing={0}>
                                <Button
                                    variant="text"
                                    startIcon={<AttachmentTwoToneIcon />}
                                    type="button"
                                    onClick={() => setShowExample(!showExample)}
                                >
                                    {showExample === false ? intl.formatMessage({id: 'Good CVEG'}) : intl.formatMessage({id: 'Close'})}
                                </Button>
                                <Stack direction="row" justifyContent="flex-end">
                                    <LoadingButton variant="contained" type="submit" onClick={handleButtonClickPlan} loading={loading || status.plan === 2}>
                                        {(displayPlan === true && optionPlan.length > 0) ? intl.formatMessage({id: 'Regenerate'}) : intl.formatMessage({id: 'Generate'})}
                                    </LoadingButton>
                                </Stack>
                            </Grid>
                            {/* Display API status and message */}
                            {status.plan === 2 && <Typography><FormattedMessage id='Loading' />...</Typography>}
                            {status.plan === 5 && userInputPlan.trim() !== '' && (
                                <Typography color="error">{intl.formatMessage({id: "Generate Error"}) + apiMessagePlan}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </MainCard>
                {showExample && <Examples category={4} />}
                {(displayPlan === true && optionPlan.length > 0) && (
                    <MainCard
                        title={intl.formatMessage({id: "Future Para"})}
                        secondary={
                            <Tooltip title={intl.formatMessage({id: "Export"})} placement="top-start">
                                <IconButton>
                                    <ExportToWordButton content={optionPlan} fileName={"plan"} />
                                </IconButton>
                            </Tooltip>
                        }
                    >
                        <Grid item xs={12} style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                            {intl.formatMessage({id: "Please Choose"}) + intl.formatMessage({id: "Future Para"})}
                        </Grid>
                        {(status.plan === 2 && optionPlan.length === 0) ? (
                            <SkeletonCard />
                        ) : (
                            <Grid>
                                {
                                    optionPlan.map((op, i) => {
                                        return (
                                            <SelectableCard
                                                index={i}
                                                content={op}
                                                isSelected={selectedCardPlan === i}
                                                onSelect={handleCardClickPlan}
                                                onContentChange={handleContentChange}
                                            />
                                        )
                                    })
                                }
                            </Grid>
                        )}
                        {optionPlan.length > 0 && (
                            <div style={{ textAlign: 'right' }}>
                                <LoadingButton variant="contained" onClick={handleOption} loading={loading || status.plan === 2}>
                                    <FormattedMessage id="Submit" />
                                </LoadingButton>
                            </div>
                        )}
                    </MainCard>
                )}
            </div>
            }
        </Grid>
    );
}
export default Plan;
