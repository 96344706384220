import axiosInstance from './config'

const getCredit = () => {
    return axiosInstance.get('/user/getCredit')
}

const updateCredit = (credit) => {
    return axiosInstance.post('/user/updateCredit')
}

export {getCredit, updateCredit}