import axiosInstance from './config'

const parseTemplate = (file) => {
    return axiosInstance.post(`/2b/file/analyze`, file, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export { parseTemplate }