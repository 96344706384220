import axios from 'axios'
import { useNavigate } from 'react-router-dom';
const axiosInstance = axios.create({timeout: 180000, withCredentials: true})

import { setCredit } from 'store/slices/account';

axiosInstance.defaults.baseURL = "https://api.admitgen.com"

axiosInstance.interceptors.request.use(req => {
    const token = localStorage.getItem("serviceToken");
    if (token) {
        req.headers['Authorization'] = token;
    }

    return req;
})

axiosInstance.interceptors.response.use(res => {
    if (res.data.credit) {
        console.log(res.data.credit)
    }
    return res; 
})

export default axiosInstance
