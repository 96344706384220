import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    credit: 0
}

const account = createSlice({
    name: 'account',
    initialState: initialState,
    reducers: {
        login: (state, action) => {
            const d = action.payload;
            state.isLoggedIn = d.isLoggedIn;
            state.user = d.user;
        },

        logout: (state, action) => {
            const d = action.payload;
            state.isLoggedIn = false;
            state.user = null;
        },

        setCredit: (state, action) => {
            const d = action.payload;
            state.credit = d;
        }
    }
})

export default account.reducer;

export const {login, logout, setCredit} = account.actions;